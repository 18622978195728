import filesize from "filesize";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default (number: number, isStartingPrice?: boolean): string => {
  let formattedNumber: string = "";

  if (isStartingPrice) {
    formattedNumber += "ab ";
  }

  if (number === 0 || number === null) {
    formattedNumber += isStartingPrice ? "0,00 €" : "kostenlos";
  } else {
    formattedNumber += new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(number);
  }

  return formattedNumber;
};

export const formatSize = (size: number): string =>
  filesize(size, { base: 10, round: 1 });

export const formatUnixToRelative = (unixDate: number): string =>
  dayjs.unix(unixDate).fromNow();

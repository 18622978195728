export const GET_PRODUCTS_BY_CATEGORY_SLUG = `
  query GetProductsByCategorySlug($slug: [String]) {
    productCategories(where: {slug: $slug}) {
      nodes {
        id
        name
        products(first: 9999, where: {status: PUBLISH, orderby: {field: TITLE, order: ASC}}) {
          nodes {
            id
            title
            startingPrice
            hasMultiplePrices
            productDetails {
              price
              images {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = `
  query GetAllProducts {
    products(first: 9999, where: {status: PUBLISH, orderby: {field: TITLE, order: ASC}}) {
      nodes {
        id
        title
        startingPrice
        hasMultiplePrices
        productDetails {
          price
          images {
            sourceUrl
          }
        }
      }
    }
  }
`;

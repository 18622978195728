export const GET_MENU_ITEMS = `
  query GetMenus {
    menus {
      nodes {
        name
        slug
        id
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  }
`;

import * as React from "react";
import { NavHashLink } from "react-router-hash-link";

// Types
import { Subheader } from "../../types/Header.types";

// Styles
import "./SubheaderDefault.scss";

interface SidebarLink {
  label: string;
  sectionId: string;
}

const SubheaderDefault: React.FC<Subheader> = (props: Subheader) => {
  const { data } = props;

  const scrollWidthOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div>
      <div className="subheader__default">
        <div className="container">
          {data && (
            <div className="subheader__default__wrapper">
              {data.map((item: SidebarLink) => (
                <NavHashLink
                  smooth
                  key={item.sectionId}
                  to={`#${item.sectionId.trim()}`}
                  className="subheader__default__item"
                  activeClassName="active"
                  scroll={(el) => scrollWidthOffset(el)}
                  isActive={(match, location) => {
                    if (`#${item.sectionId.trim()}` === location.hash)
                      return true;
                    return false;
                  }}
                  // onClick={handleLinkClick}
                >
                  {item.label}
                </NavHashLink>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubheaderDefault;

import * as React from "react";
import ReactDOM from "react-dom";
import { useQuery } from "@apollo/react-hooks";
import Header from "../Header/Header";
import gql from "graphql-tag";

// Components
import ImageComparison from "../ImageComparison/ImageComparison";
import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";

// Misc.
import { GET_BRANDING_PAGE } from "./Branding.queries";

// Styles
import "./Branding.scss";

const QUERY_BRANDING_PAGE = gql`
  ${GET_BRANDING_PAGE}
`;

interface SidebarLink {
  label: string;
  sectionId: string;
}

interface PageData {
  title: string;
  content_mapped: string;
  sidebarLinks: {
    sections: SidebarLink[];
  };
}

const Branding: React.FC = () => {
  const [guideline, setGuideline] = React.useState<PageData | undefined>(
    undefined
  );
  const [headline, setHeadline] = React.useState<string | undefined>(undefined);

  const handleComplete = (data: any) => {
    const {
      headline: brandingHeadline,
    } = data.sonnenEinstellungen.textSettings.branding;

    setHeadline(brandingHeadline);
    setGuideline(data.guideline);
  };

  const { loading } = useQuery(QUERY_BRANDING_PAGE, {
    variables: { id: 3593 },
    onCompleted: handleComplete,
  });

  React.useEffect(() => {
    if (guideline) {
      const imageComparisonElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        ".sonnen-image-comparison"
      );

      imageComparisonElements.forEach((element, index) => {
        const imageMain = element.dataset.imageMain;
        const imageComparison = element.dataset.imageComparison;
        const imageWidth = element.dataset.imageWidth;
        const imageHeight = element.dataset.imageHeight;

        ReactDOM.render(
          <ImageComparison
            main={imageMain}
            comparison={imageComparison}
            width={imageWidth}
            height={imageHeight}
          />,
          document.querySelectorAll(".sonnen-image-comparison")[index]
        );
      });
    }
  }, [guideline]);

  return (
    <div className="page-branding">
      <Header subheaderData={guideline?.sidebarLinks.sections} />
      <div className="page-branding__content container">
        {loading && <Loader />}
        {guideline && (
          <div className="row">
            <div className="col-sm-12">
              <div className="guideline__title">
                <h1>{headline || guideline.title}</h1>
              </div>
              <div className="page__content">
                <div
                  dangerouslySetInnerHTML={{ __html: guideline.content_mapped }}
                  // onClick={(event: React.MouseEvent<HTMLElement>) =>
                  //   captureLinkClicks(event, history)
                  // }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Branding;

import { LOCAL_STORAGE_POPUP_ID } from "../constants/LocalStorage";

const getPopupId = () => {
  return localStorage.getItem(LOCAL_STORAGE_POPUP_ID);
};

export const savePopupIdToLocalStorage = (id: string) => {
  localStorage.setItem(LOCAL_STORAGE_POPUP_ID, id);
};

export const comparePopupId = (id: string) => {
  const currentId = getPopupId();

  if (!currentId || currentId != id) {
    return false;
  }

  return true;
};

export const GET_BRANDING_PAGE = `
  query Guideline($id: Int) {
    guideline(id: $id) {
      title
      content_mapped
      sidebarLinks {
        sections {
          label
          sectionId
        }
      }
    }
    sonnenEinstellungen {
      textSettings {
        branding {
          headline
        }
      }
    }
  }
`;

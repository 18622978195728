import { ApolloLink } from "apollo-boost";

// Constants
import { LOCAL_STORAGE_AUTH_TOKEN } from "../../constants/LocalStorage";

export const tokenLink = new ApolloLink((operation, forward) => {
  const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  operation.setContext({
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : "",
    },
  });

  return forward(operation);
});

import * as React from "react";

// Utils
import { useBasketDispatch } from "../../contexts/Basket.context";
import convertToCurrency from "../../utils/FormatNumber";

// Styles
import "./ShipmentSelect.scss";
import { SHIPMENT_EXPRESS } from "../../constants/ShipmentCost";

interface ShipmentSelect {
  value: string;
}

const ShipmentSelect: React.FC<ShipmentSelect> = (props: ShipmentSelect) => {
  const { value } = props;
  const dispatch = useBasketDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "updateShipment",
      payload: { shipment: event.currentTarget.value },
    });
  };

  return (
    <div className="shipment-select">
      <div className="shipment-select__item">
        <input
          type="radio"
          id="standard"
          name="shipment"
          value="standard"
          onChange={handleChange}
          checked={value === "standard"}
        />
        <label htmlFor="standard">
          <p>Standard-Versand</p>
          <p>kostenlos</p>
        </label>
      </div>

      <div className="shipment-select__item">
        <input
          type="radio"
          id="express"
          name="shipment"
          value="express"
          checked={value === "express"}
          onChange={handleChange}
        />
        <label htmlFor="express">
          <p>Express-Versand</p>
          <p>{convertToCurrency(SHIPMENT_EXPRESS)}</p>
        </label>
      </div>
    </div>
  );
};

export default ShipmentSelect;

import * as React from "react";

// Components
import { OrderStatus } from "../OrderStatus/OrderStatus";
import OrderItemDetails from "../OrderItemDetails/OrderItemDetails";
import IconCaretDown from "../../icons/IconCaretDown";

// Utils
import { Order } from "../../types/Order.types";
import { SHIPMENT_EXPRESS } from "../../constants/ShipmentCost";
import convertToCurrency from "../../utils/FormatNumber";

// Styles
import "./OrderItem.scss";

interface OrderItem {
  order: Order;
  cancelOrder?: (orderId: string) => void;
}

const OrderItem: React.FC<OrderItem> = (props: OrderItem) => {
  const { order, cancelOrder } = props;
  const [expanded, setExpanded] = React.useState(false);

  const calculateAmountOfItems = () => {
    const { items } = order.orderDetails;
    const amount = items.reduce((sum, item) => sum + item.amount, 0);
    return amount;
  };

  const calculateSumOfItems = () => {
    const { items } = order.orderDetails;
    let sum = items.reduce((sum, item) => sum + item.amount * item.price, 0);

    if (order.orderDetails.shipment === "express") sum += SHIPMENT_EXPRESS;

    return convertToCurrency(sum);
  };

  const convertDateString = () => {
    const date = new Date(order.date);
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return `${day}.${month}.${year}`;
  };

  return (
    <>
      <tr className="order-item" onClick={() => setExpanded(!expanded)}>
        <td className="order-item__id">{order.databaseId}</td>
        <td className="order-item__date hidden-sm">{convertDateString()}</td>
        <td className="order-item__amount hidden-sm">
          {calculateAmountOfItems()}
        </td>
        <td className="order-item__price hidden-sm">{calculateSumOfItems()}</td>
        <td className="order-item__status">
          <OrderStatus
            status={order.orderStatuses.nodes[0].slug}
            description={
              order.orderStatuses.nodes[0].description || "NO LABEL SET"
            }
          />
        </td>
        <td>
          <IconCaretDown />
        </td>
      </tr>
      <tr className={`order-item-toggle ${expanded && "--visible"}`}>
        <td colSpan={6}>
          <div className="col-12 col-sm-10 offset-sm-1">
            <OrderItemDetails
              orderId={order.id}
              publicOrderId={order.databaseId}
              orderStatus={order.orderStatuses.nodes[0].slug}
              details={order.orderDetails}
              cancelOrder={cancelOrder}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default OrderItem;

import * as React from "react";

// Types
import { ShipmentInfo } from "../../types/Product.types";

interface IShipmentDetail {
  shipment: "express" | "standard";
  standard: ShipmentInfo;
  express: ShipmentInfo;
}

const ShipmentDetail: React.FC<IShipmentDetail> = (props: IShipmentDetail) => {
  const { shipment, standard, express } = props;

  const getShipmentString = () => {
    if (shipment === "express" && express.supported) {
      return `Express (${express.shippingtime})`;
    }

    return `Standard (${standard.shippingtime})`;
  };

  return (
    <div>
      <span>Lieferzeit: {getShipmentString()}</span>
    </div>
  );
};

export default ShipmentDetail;

import * as React from "react";

// Styles
import "./ProductDetailList.scss";
// Utils
import { ProductDetail } from "../../types/Product.types";

interface ProductDetailList {
  details: ProductDetail[];
  headline?: string;
}

const ProductDetailList: React.FC<ProductDetailList> = (
  props: ProductDetailList
) => {
  const { details, headline = "Details" } = props;

  return (
    <div className="product-detail-list">
      <p className="product-detail-list__headline">{headline}</p>
      <ul>
        {details &&
          details.map((detailItem: ProductDetail, index: number) => (
            <li key={index}>{detailItem.detail}</li>
          ))}
      </ul>
    </div>
  );
};

export default ProductDetailList;

import * as React from "react";
import { Link, useRouteMatch } from "react-router-dom";

// Component
import AddressField from "../AddressField/AddressField";

// Context
import { useUserState } from "../../contexts/User.context";

// Styles
import "./CheckoutAddressBar.scss";

const CheckoutAddressBar: React.FC = () => {
  const userState = useUserState();
  const { path } = useRouteMatch();

  return (
    <div className="row checkout-address-bar">
      {userState.user.addresses.addressBilling &&
        userState.user.addresses.addressShipping && (
          <>
            <div className="col-10 offset-1">
              <div className="checkout-address">
                <h4>Rechnungsadresse</h4>
                {userState.user.addresses.addressBilling.name ? (
                  <AddressField
                    address={userState.user.addresses.addressBilling}
                  />
                ) : (
                  <p className="--muted">Keine Rechnungsadresse hinterlegt</p>
                )}
                <Link
                  to={`${path}/address/invoice`}
                  className="button --outline mt-4"
                >
                  {`Rechnungsadresse ${
                    userState.user.addresses.addressBilling.name
                      ? "bearbeiten"
                      : "hinzufügen"
                  }`}
                </Link>
              </div>
            </div>
            <div className="col-10 offset-1">
              <hr />
            </div>
            <div className="col-10 offset-1">
              <div className="checkout-address">
                <h4>Lieferadresse</h4>
                {userState.user.addresses.addressShipping.name ? (
                  <AddressField
                    address={userState.user.addresses.addressShipping}
                  />
                ) : (
                  <p className="--muted">Keine Lieferadresse hinterlegt</p>
                )}
                <Link
                  to={`${path}/address/shipping`}
                  className="button --outline mt-4"
                >
                  {`Lieferadresse ${
                    userState.user.addresses.addressShipping.name
                      ? "bearbeiten"
                      : "hinzufügen"
                  }`}
                </Link>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default CheckoutAddressBar;

export const GET_USER_ADDRESSES = `
  query GetUserAddresses {
    viewer {
      id
      addresses {
        addressBilling {
          address2
          address
          company
          city
          country
          name
          zip
          surname
        }
        addressShipping {
          address2
          address
          city
          company
          country
          surname
          name
          zip
        }
      }
    }
  }
`;

export const REGISTER = `
  mutation RegisterUser($username: String!, $email: String!, $comment: String, $company: String!, $firstName: String!) {
    registerUser(input: {
        clientMutationId: "registerUser",
        username: $username,
        firstName: $firstName,
        email: $email,
        description: $comment,
        company: $company
      }) {
      user {
        email
      }
    }
  }
`;

export const LOGIN = `
  mutation LoginUser($username: String!, $password: String!) {
    login( input: {
      clientMutationId: "loginUser",
      username: $username,
      password: $password
    } ) {
      authToken
      refreshToken
      user {
        id
        firstName
        lastName
        avatar {
          url
        }
        addresses {
          addressBilling {
            address
            address2
            city
            company
            country
            fieldGroupName
            name
            surname
            zip
          }
          addressShipping {
            address
            address2
            city
            company
            country
            fieldGroupName
            name
            surname
            zip
          }
        }
      }
    }
  }
`;

export const PROTECTED_QUERY = `
  query ProtectedQuery {
    viewer {
      avatar {
        url
      }
      id
      lastName
      firstName
      addresses {
        addressBilling {
          address
          address2
          city
          company
          country
          name
          surname
          zip
        }
        addressShipping {
          address
          address2
          city
          company
          country
          name
          surname
          zip
        }
      }
    }
    sonnenEinstellungen {
      popupSettings {
        active
        popupImage {
          databaseId
          mediaItemUrl
        }
      }
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS = `
  mutation UpdateShippingAddress($address: String!) {
    updateAddressShipping(input: {clientMutationId: "updateShippingAddress", address: $address}) {
      address
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = `
  mutation UpdateBillingAddress($address: String!) {
    updateAddressBilling(input: {clientMutationId: "updateBillingAddress", address: $address}) {
      address
    }
  }
`;

export const GET_USER_ORDERS = `
  query GetUserOrders {
    viewer {
      id
      orders {
        nodes {
          id
          databaseId
          orderStatuses {
            nodes {
              slug
              description
              id
            }
          }
          date
          orderDetails {
            items {
              item {
                ... on Product {
                  id
                  title
                }
              }
              amount
              price
            }
            shipment
            addressBilling {
              address
              address2
              city
              company
              country
              name
              surname
              zip
            }
            addressShipping {
              address
              address2
              company
              city
              country
              name
              zip
              surname
            }
          }
        }
      }
    }
  }
`;

export const RESET_USER_PASSWORD = `
  mutation resetUserPassword($key: String!, $login: String!, $password: String!) {
    resetUserPassword( input: {
      clientMutationId: "resetUserPassword",
      key: $key,
      login: $login
      password: $password
    } ) {
      clientMutationId
    }
  }
`;

export const SEND_PASSWORD_RESET_MAIL = `
  mutation sendPasswordResetMail($username: String!) {
    sendPasswordResetEmail( input: {
      clientMutationId: "sendPasswordResetEmail",
      username: $username
    } ) {
      clientMutationId
    }
  }
`;

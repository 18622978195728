import * as React from "react";
import { Link, NavLink } from "react-router-dom";

// Misc.
import { MenuItem } from "../../types/Menu.types";

const HeaderMenuItem: React.FC<MenuItem> = (props: MenuItem) => {
  const { label, url } = props;

  if (url.indexOf("/") === 0) {
    return (
      <NavLink to={url}>
        <li>{label}</li>
      </NavLink>
    );
  }

  return (
    <Link to={url} target="_blank" rel="noopener noreferrer">
      {label}
    </Link>
  );
};

export default HeaderMenuItem;

import * as React from "react";

interface IconCaretDown {
  style?: any;
}

const IconCaretDown: React.FC<IconCaretDown> = (props: IconCaretDown) => {
  const { style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#9396a1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <path d="M6 9l6 6 6-6" />
    </svg>
  );
};

export default IconCaretDown;

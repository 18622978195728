import React from "react";

interface DownloadIcon {
  size?: number;
  color?: string;
  strokeWidth?: number;
  onClick?: () => void;
}

const Download: React.FC<DownloadIcon> = ({
  size = 30,
  color = "#282E44",
  strokeWidth = 1,
  onClick,
}: DownloadIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="butt"
    strokeLinejoin="round"
    onClick={onClick}
  >
    <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
  </svg>
);

export default Download;

import { onError } from "apollo-link-error";
import { GraphQLError } from "graphql";

// Constants
import {
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "../../constants/LocalStorage";

interface DebugError extends GraphQLError {
  debugMessage?: string;
}

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.error("Network Error");
    console.log(networkError);
  }

  if (graphQLErrors) {
    console.error("[Error] GraphQL");
    console.log(graphQLErrors);
    graphQLErrors.forEach((error: DebugError) => {
      if (
        error.debugMessage &&
        error.debugMessage.indexOf("invalid-secret-key") >= 0
      ) {
        window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        window.localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);

        alert(
          "[TOKEN_ERROR] For Security Reasons a new validation of User's Login Credentials is necessary"
        );

        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }
    });
  }
});

import { BasketItem, ProductDetails } from "../types/Basket.types";
import { Variant } from "../types/Product.types";

export const enrichenBasketData = (basketData: any, remoteData: any[]) => {
  const enrichedBasketData: BasketItem[] = basketData.items.map(
    (item: BasketItem) => {
      const remote = remoteData.filter(
        (remoteItem: ProductDetails) => remoteItem.id === item.id
      )[0];

      const ean = item.variant.ean;
      const variantDetails = remote.productDetails.variantSettings.variants.filter(
        (variant: Variant) => variant.ean === ean
      )[0];

      return {
        ...item,
        ...remote,
        variant: {
          ...variantDetails,
          label: variantDetails.variant,
        },
        productDetails: {
          ...remote.productDetails,
          price: variantDetails.price,
        },
      };
    }
  );

  return enrichedBasketData;
};

import * as React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";

// Components
import Loader from "../Loader/Loader";

// Utils
import { GET_USER_ORDERS } from "../../queries/User.queries";
import { CANCEL_ORDER } from "../Basket/Basket.queries";
import { Order } from "../../types/Order.types";
import OrderOverview from "../OrderOverview/OrderOverview";

// Styles
import "./Orders.scss";

const QUERY_ORDERS = gql`
  ${GET_USER_ORDERS}
`;

const MUTATION_ORDER = gql`
  ${CANCEL_ORDER}
`;

const Orders: React.FC = () => {
  const [orders, setOrders] = React.useState<Order[] | null>(null);
  const [closedOrders, setClosedOrders] = React.useState<Order[] | null>(null);
  const { loading, error, data: orderData } = useQuery(QUERY_ORDERS);
  const [cancelOrder] = useMutation(MUTATION_ORDER);

  if (error) {
    console.log(error);
  }

  const handleCancelOrder = (orderId: string) => {
    cancelOrder({
      variables: { id: orderId },
    }).then(() => {
      toast.info("Die Bestellung wurde erfolgreich storniert.");
    });
  };

  React.useEffect(() => {
    if (orderData) {
      const { nodes } = orderData.viewer.orders;
      const ordersOpen = nodes.filter(
        (node: Order) =>
          node.orderStatuses.nodes[0].slug === "received" ||
          node.orderStatuses.nodes[0].slug === "processed"
      );

      const ordersClosed = nodes.filter(
        (node: Order) =>
          node.orderStatuses.nodes[0].slug === "completed" ||
          node.orderStatuses.nodes[0].slug === "cancelled"
      );

      setClosedOrders(ordersClosed);
      setOrders(ordersOpen);
    }
  }, [orderData]);

  return (
    <div className="orders row">
      <div className="col-12">
        <h1>Laufende Bestellungen</h1>
      </div>

      {loading && (
        <div className="col-12">
          <Loader />
        </div>
      )}

      {!loading && orders && (
        <div className="col-12">
          <OrderOverview cancelOrder={handleCancelOrder} orders={orders} />
        </div>
      )}
      <div className="col-12">
        <h1>Abgeschlossene Bestellungen</h1>
      </div>

      {loading && (
        <div className="col-12">
          <Loader />
        </div>
      )}

      {!loading && closedOrders && (
        <div className="col-12">
          <OrderOverview orders={closedOrders} />
        </div>
      )}
    </div>
  );
};

export default Orders;

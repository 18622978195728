import * as React from "react";

// Utils
import { Icon } from "../../types/Icon.types";

const IconAllProducts: React.FC<Icon> = (props: Icon) => {
  const { width = 16, height = 16, color = "#6e6373" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <g
        stroke={color}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M1 1h5v5H1zM1 10h5v5H1zM10 1h5v5h-5zM10 10h5v5h-5z" />
      </g>
    </svg>
  );
};

export default IconAllProducts;

import decodeJWT from "jwt-decode";
import { TokenRefreshLink } from "apollo-link-token-refresh";

// Constants
import {
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "../../constants/LocalStorage";
import Config from "../../config";

interface AuthToken {
  data: any;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
}

export const authLink: any = new TokenRefreshLink({
  accessTokenField: "authToken",
  isTokenValidOrUndefined: () => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    if (authToken && typeof authToken === "string") {
      const decodedToken: AuthToken = decodeJWT(authToken);
      const nowInSeconds = Math.floor(Date.now() / 1000);

      if (decodedToken) {
        if (
          decodedToken.exp >= nowInSeconds &&
          decodedToken.nbf <= nowInSeconds
        ) {
          return true;
        }
      }
    } else if (authToken === null) {
      return true;
    }

    console.log("[authLink] Token invalid");
    return false;
  },
  fetchAccessToken: async () => {
    const response = await fetch(Config.gqlUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
              mutation RefreshAuthToken {
                refreshJwtAuthToken(input: {
                  clientMutationId: "refreshJwtAuthToken",
                  jwtRefreshToken: "${localStorage.getItem(
                    LOCAL_STORAGE_REFRESH_TOKEN
                  )}"
                }) {
                  authToken
                }
              }
          `,
      }),
    });

    const data = await response.json();

    return data;
  },
  handleResponse: () => (response: any) => {
    console.log("[authLink] Refresh Token");

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    return response.data.refreshJwtAuthToken;
  },
  handleFetch: (authToken) => {
    if (authToken) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, authToken);
    }
  },
  handleError: (err) => {
    console.log(
      "Error with Token Validation when trying to get new Token with Refresh Token."
    );
    console.log(err);
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }

    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  },
});

import * as React from "react";

// Styles
import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__content">
          <p className="footer__legal">© sonnen GmbH – all rights reserved</p>
        </div>
        <div className="footer__content">
          <a href="https://privacy-docs-production.s3.eu-central-1.amazonaws.com/Uy8TDH8MJF2hJawTrGfxVVV4?response-content-disposition=inline%3B%20filename%3D%22201702_Nutzungsbedingungen%20Kundenportal%20sonnen%20und%20eServices%20%2528DE%2529.pdf%22%3B%20filename%2A%3DUTF-8%27%27201702_Nutzungsbedingungen%2520Kundenportal%2520sonnen%2520und%2520eServices%2520%2528DE%2529.pdf&response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJFF2UBUPFHOSV36A%2F20200701%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20200701T222053Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=12abdc3dd2522465761ccc20fe26a2ea4c53a95ea50b90cf400d19379fb4bad9">
            Terms of Use
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

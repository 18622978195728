export const GET_PRODUCT_CATEGORIES = `
  query GetProductCategories {
    sonnenEinstellungen {
      textSettings {
        shop {
          headline
          descriptionCategories
        }
      }
    }
    productCategories {
      nodes {
        slug
        description
        id
        name
        productCategoryId
        productCategoryTaxonomy {
          categoryIcon {
            large {
              altText
              sourceUrl
            }
            small {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useFormik } from "formik";
import gql from "graphql-tag";

// Components
import InputText from "../InputText/InputText";

// Styles
import "./AddressShipping.scss";

// Utils
import { useUserDispatch, useUserState } from "../../contexts/User.context";
import { Address } from "../../types/Basket.types";
import { FetchResult } from "apollo-boost";
import {
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_BILLING_ADDRESS,
} from "../../queries/User.queries";

const MUTATION_SHIPPING_ADDRESS = gql`
  ${UPDATE_SHIPPING_ADDRESS}
`;

const MUTATION_BILLING_ADDRESS = gql`
  ${UPDATE_BILLING_ADDRESS}
`;

interface AddressShipping {
  type: "addressShipping" | "addressBilling";
}

const AddressShipping: React.FC<AddressShipping> = (props: AddressShipping) => {
  const { type } = props;
  const history = useHistory();
  const userContext = useUserState();
  const userDispatch = useUserDispatch();
  const [updateBillingAddress] = useMutation(MUTATION_BILLING_ADDRESS);
  const [updateShippingAddress] = useMutation(MUTATION_SHIPPING_ADDRESS);

  const afterUpdateShippingAddress = (mutationResponse: FetchResult<any>) => {
    const { address } = mutationResponse.data.updateAddressShipping;

    userDispatch({
      type: "updateAddressShipping",
      payload: JSON.parse(address),
    });

    history.push("/shop/checkout");
  };

  const afterUpdateBillingAddress = (mutationResponse: FetchResult<any>) => {
    const { address } = mutationResponse.data.updateAddressBilling;

    userDispatch({
      type: "updateAddressBilling",
      payload: JSON.parse(address),
    });

    history.push("/shop/checkout");
  };

  const formik = useFormik({
    // TODO: Replace NULL values with ""
    initialValues: userContext.user.addresses[type]
      ? { ...(userContext.user.addresses[type] as Address) }
      : {
          address: "",
          address2: "",
          city: "",
          company: "",
          country: "",
          name: "",
          surname: "",
          zip: "",
        },
    onSubmit: (values: Address) => {
      if (type === "addressShipping") {
        updateShippingAddress({
          variables: { address: JSON.stringify(values) },
        }).then(afterUpdateShippingAddress);
      } else {
        updateBillingAddress({
          variables: { address: JSON.stringify(values) },
        }).then(afterUpdateBillingAddress);
      }
    },
  });

  return (
    <div className="address-shipping">
      <div className="row">
        <div className="col-12">
          {type === "addressShipping" ? (
            <h1>Lieferadresse bearbeiten</h1>
          ) : (
            <h1>Rechnungsadresse bearbeiten</h1>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <InputText
                id="company"
                label="Firma"
                value={formik.values.company}
                className="col-12"
                onChange={formik.handleChange}
              />
            </div>
            <div className="row">
              <InputText
                id="name"
                label="Vorname"
                value={formik.values.name}
                className="col-6"
                onChange={formik.handleChange}
                required
              />
              <InputText
                id="surname"
                label="Nachname"
                value={formik.values.surname}
                className="col-6"
                onChange={formik.handleChange}
                required
              />
            </div>
            <div className="row">
              <InputText
                id="address"
                label="Adresse"
                value={formik.values.address}
                className="col-12"
                onChange={formik.handleChange}
                required
              />
            </div>
            <div className="row">
              <InputText
                id="address2"
                label="Adresse 2"
                value={formik.values.address2}
                className="col-12"
                onChange={formik.handleChange}
              />
            </div>
            <div className="row">
              <InputText
                id="city"
                label="Stadt"
                value={formik.values.city}
                className="col-8"
                onChange={formik.handleChange}
                required
              />
              <InputText
                id="zip"
                label="Postleitzahl"
                value={formik.values.zip}
                className="col-4"
                onChange={formik.handleChange}
                required
              />
            </div>
            <div className="row">
              <InputText
                id="country"
                label="Land"
                value={formik.values.country}
                className="col-12"
                onChange={formik.handleChange}
                required
              />
            </div>
            <div className="row">
              <div className="col-6">
                <Link to="/shop/checkout" className="button --outline">
                  Abbrechen
                </Link>
              </div>
              <div className="col-6">
                <button type="submit">Speichern</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddressShipping;

export const GET_DEFAULT = `
  query DefaultQuery($uri: ID!) {
    page(id: $uri, idType: URI) {
      content_mapped
      subnavigation {
        sections {
          sectionId
          label
        }
      }
    }
  }
`;

import * as React from "react";
import {
  Link,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";

// Components
import InputText from "../InputText/InputText";

// Utils
import { REGISTER } from "../../queries/User.queries";

// Styles
import "./Register.scss";
import BackgroundImage from "../../static/images/login-background.jpg";
import RegisterIllustration from "../../static/images/login-illustration.png";
import CompletedIllustration from "../../static/images/order-completed-illustration.svg";
import SonnenLogo from "../../static/images/logo-sonnen-white.svg";

const MUTATION_REGISTER = gql`
  ${REGISTER}
`;

const Register: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [register] = useMutation(MUTATION_REGISTER);

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      company: "",
      comment: "",
    },
    onSubmit: (values) => {
      register({
        variables: {
          ...values,
          email: values.username,
          firstName: values.name,
        },
      })
        .then((response) => {
          if (response.data.registerUser) {
            history.push("/register/success");
          }
        })
        .catch((error) => {
          if (
            error.message.indexOf(
              "Diese E-Mail-Adresse wurde bereits registriert"
            )
          ) {
            toast.error("Diese E-Mail-Adresse wird bereits verwendet.");
          }
        });
    },
  });

  return (
    <div
      className="register"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="register-container">
        <div className="register-container__inner">
          <div className="register-container__header">
            <img src={SonnenLogo} alt="sonnen" />
          </div>
          <Switch>
            <Route exact path={path}>
              <div className="register-container__intro">
                <img src={RegisterIllustration} alt="Neue Registrierung" />
                <h1>Einmalig registrieren und Partner Support Tool nutzen</h1>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <InputText
                  id="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  required
                />
                <InputText
                  id="username"
                  label="E-Mail"
                  type="email"
                  className="mt-3"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  required
                />
                <InputText
                  id="company"
                  label="Firma"
                  className="mt-3"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  required
                />
                <InputText
                  id="comment"
                  label="Möchten Sie uns etwas mitteilen?"
                  className="mt-3"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
                <button type="submit" className="mt-4">
                  Registrieren
                </button>
              </form>
              <p className="register-container__register text-muted">
                Sie haben schon ein Konto? <Link to="/login">Zum Login.</Link>
              </p>
            </Route>
            <Route path={`${path}/success`}>
              <div className="register-container__intro">
                <img
                  src={CompletedIllustration}
                  alt="Registrierung abgeschlossen"
                />
                <h1>Vielen Dank!</h1>
                <p>
                  Ihre Registrierung ist abgeschlossen.
                  <br /> Wir melden uns bald bei Ihnen.
                </p>
                <Link className="button my-4" to="/login">
                  Zurück zum Login
                </Link>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Register;

import * as React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

// Styles
import "./Card.scss";

interface Card {
  title: string;
  href: string;
  text: string;
  image: {
    src: string;
    altText: string;
  };
  className?: string;
  externalLink?: boolean;
  imageStyle?: "contain" | "cover";
  textAlign?: "left" | "center" | "right";
  headlineSize?: "small" | "medium";
  paddingSize?: "small" | "medium";
}

const Card: React.FC<Card> = (props: Card) => {
  const {
    title,
    href,
    text,
    image,
    className,
    externalLink = false,
    imageStyle = "contain",
    textAlign = "center",
    headlineSize = "medium",
  } = props;

  const cardClassName = classNames("card container", {
    [`--text-${textAlign}`]: true,
    [`--headline-${headlineSize}`]: true,
    [`--image-${imageStyle}`]: true,
    [`${className}`]: className,
  });

  const cardImageClassName = classNames("card__image", {
    "col-5 col-sm-12": imageStyle === "contain",
    "col-12": imageStyle === "cover",
  });

  const cardInfoClassName = classNames("card__info", {
    "col-7 col-sm-12": imageStyle === "contain",
    "col-12": imageStyle === "cover",
  });

  return (
    <div className={cardClassName}>
      {externalLink ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <div className="card__inner row">
            <div
              className={cardImageClassName}
              style={{ backgroundImage: `url(${image.src})` }}
            />
            <div className={cardInfoClassName}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
              <p className="--muted">{text}</p>
            </div>
          </div>
        </a>
      ) : (
        <Link to={href}>
          <div className="card__inner row">
            <div
              className={cardImageClassName}
              style={{ backgroundImage: `url(${image.src})` }}
            />
            <div className={cardInfoClassName}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
              <p className="--muted">{text}</p>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Card;

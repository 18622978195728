export const GET_SUPPORT_CATEGORIES = `
  query GetSupportCategories {
    sonnenEinstellungen {
      textSettings {
        support {
          headline
          descriptionCategories
        }
      }
    }
    supportCategories {
      nodes {
        slug
        description
        id
        name
        supportCategoryId
        supportCategoryTaxonomy {
          categoryIcon {
            large {
              altText
              sourceUrl
            }
            small {
              altText
              sourceUrl
            }
          }
          url
        }
      }
    }
  }
`;

import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

// Components
import Header from "../Header/Header";
import Card from "../Card/Card";
import Footer from "../Footer/Footer";

// Utils
import { GET_SUPPORT_CATEGORIES } from "./Support.queries";

// Types
import { SupportCategory } from "../../types/Support.types";

const QUERY_SUPPORT_CATEGORIES = gql`
  ${GET_SUPPORT_CATEGORIES}
`;

const Support: React.FC = () => {
  const [supportCategories, setSupportCategories] = React.useState<
    SupportCategory[]
  >([]);
  const [headline, setHeadline] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleComplete = (data: any) => {
    if (data) {
      const categories = data.supportCategories.nodes;
      const {
        descriptionCategories: categoryDescription,
        headline: supportHeadline,
      } = data.sonnenEinstellungen.textSettings.support;

      setSupportCategories(categories);
      setHeadline(supportHeadline);
      setDescription(categoryDescription);
    }
  };

  const { error } = useQuery(QUERY_SUPPORT_CATEGORIES, {
    onCompleted: handleComplete,
  });

  if (error) {
    console.error(`[Shop.tsx] ${error.message}`);
  }

  return (
    <div className="page-shop">
      <Header />
      <div className="container">
        <div className="row my-4">
          <div className="col-6">
            <h1>{headline}</h1>
            <p>{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="cardContainer">
            {supportCategories.map((category: SupportCategory) => (
              <div className="col" key={category.id}>
                <Card
                  title={category.name}
                  href={category.supportCategoryTaxonomy.url}
                  text={category.description}
                  externalLink={true}
                  image={{
                    src:
                      category.supportCategoryTaxonomy.categoryIcon.large
                        .sourceUrl,
                    altText:
                      category.supportCategoryTaxonomy.categoryIcon.large
                        .altText,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;

import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

// Context
import { useBasketDispatch } from "../../contexts/Basket.context";

// Components
import ProductImages from "../ProductImages/ProductImages";
import InputSelect from "../InputSelect/InputSelect";
import BackButton from "../BackButton/BackButton";
import ProductDetailList from "../ProductDetailList/ProductDetailList";
import InputAmount from "../InputAmount/InputAmount";
import Loader from "../Loader/Loader";
import ProductShipmentDetails from "../ProductShipmentDetails/ProductShipmentDetails";
import InputText from "../InputText/InputText";

// Styles
import "./ProductDetails.scss";

// Utils
import { GET_PRODUCT_BY_ID } from "./ProductDetails.queries";
import convertToCurrency from "../../utils/FormatNumber";
import { Product } from "../../types/Product.types";

const QUERY_PRODUCT = gql`
  ${GET_PRODUCT_BY_ID}
`;

const ProductDetails: React.FC = () => {
  const { productId }: any = useParams();
  const [product, setProduct] = React.useState<null | Product>(null);

  const dispatch = useBasketDispatch();

  const handleComplete = (data: any) => setProduct(data.productBy);

  const formik = useFormik({
    initialValues: {
      id: productId,
      amount: 1,
      comment: "",
      variant: {},
    },
    onSubmit: (values) => {
      if (product) {
        const { variants } = product?.productDetails.variantSettings;
        let dispatchObject: any = {};

        if (variants.length === 1) {
          dispatchObject = {
            ...values,
            variant: variants[0],
          };
        } else {
          dispatchObject = { ...values };
        }

        toast.dismiss();
        toast.info(
          <div>
            Das Produkt wurde dem <Link to="/shop/basket">Warenkorb</Link>{" "}
            hinzugefügt
          </div>
        );

        dispatch({
          type: "add",
          payload: {
            ...dispatchObject,
            internalProductId: product?.productId,
            basketId: uuidv4(),
          },
        });
      }
    },
  });

  const { loading } = useQuery(QUERY_PRODUCT, {
    variables: { id: productId },
    onCompleted: handleComplete,
  });

  return (
    <div className="product-details">
      <BackButton />
      {loading && <Loader />}
      {product && (
        <div className="row">
          <div className="col-12 col-sm-6">
            <ProductImages
              images={product.productDetails.images}
              download={product.productDetails.download}
            />
          </div>
          <div className="product-details__meta col-12 col-sm-5 offset-sm-1">
            {product.productDetails.variantSettings.variants.length === 1 && (
              <p className="product-details__ean">
                Artikelnummer:{" "}
                {product.productDetails.variantSettings.variants[0].ean}
              </p>
            )}
            <h1
              dangerouslySetInnerHTML={{
                __html: product.title,
              }}
            />
            <p className="product-details__price">
              {convertToCurrency(
                product.startingPrice,
                product.hasMultiplePrices
              )}
            </p>
            <p className="product-details__description">
              {product.productDetails.description}
            </p>
            <hr />
            {product.productDetails.details && (
              <ProductDetailList details={product.productDetails.details} />
            )}
            <ProductShipmentDetails
              standard={product.productDetails.shipmentstandard}
              express={product.productDetails.shipmentexpress}
            />
            <form onSubmit={formik.handleSubmit}>
              {product.productDetails.variantSettings &&
                product.productDetails.variantSettings.variants.length > 1 && (
                  <InputSelect
                    id="variant"
                    label={product.productDetails.variantSettings.headline}
                    options={product.productDetails.variantSettings.variants}
                    value={formik.values.variant}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    required={true}
                  />
                )}
              {product.productDetails.showComment && (
                <InputText
                  id="comment"
                  label="Bestellkommentar"
                  className="my-3"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              )}
              <InputAmount
                id="amount"
                className="my-3"
                value={formik.values.amount}
                label="Anzahl"
                onChange={formik.handleChange}
                required={true}
              />
              <button className="my-3" type="submit">
                In den Warenkorb
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;

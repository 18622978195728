// graphql api url
const URL_DEV = "http://localhost:8888/graphql";
const URL_PROD = "https://marketing-support-api.chaussee21.com/graphql";

const URL_FRONT_DEV = "http://localhost:8888/";
const URL_FRONT_PROD = "https://marketing-support.chaussee21.de/";

const URL_BACK_DEV = "http://localhost:8888/";
const URL_BACK_PROD = "https://marketing-support-api.chaussee21.com/";

const DOCUMENT_PATH_DEV = "/var/www/html/";
const DOCUMENT_PATH_PROD =
  "/var/www/vhosts/chaussee21.com/marketing-support-api.chaussee21.com/";

const Config = {
  gqlUrl: process.env.NODE_ENV === "production" ? URL_PROD : URL_DEV,
  frontendUrl:
    process.env.NODE_ENV === "production" ? URL_FRONT_PROD : URL_FRONT_DEV,
  backendUrl:
    process.env.NODE_ENV === "production" ? URL_BACK_PROD : URL_BACK_DEV,
  documentPath:
    process.env.NODE_ENV === "production"
      ? DOCUMENT_PATH_PROD
      : DOCUMENT_PATH_DEV,
};

export default Config;

import { Document } from "../types/Document.types";

export const sortDocuments = (documents: Document[]) =>
  documents.sort((a: Document, b: Document) => {
    // Sort Folders above Files
    if (a.isFolder && !b.isFolder) {
      return -1;
    }

    if (!a.isFolder && b.isFolder) {
      return 1;
    }

    // Sort Alphabetically by Name
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

    return 0;
  });

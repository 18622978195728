import { StylesConfig } from "react-select";

const COLORS = {
  sonnenBlue: "#4dcff0",
  siliciumBlue: "#282e44",
  greyBlue: "#5e6373",
  greyMedium: "#9396a1",
};

const CustomStyles: StylesConfig = {
  option: (provided: React.CSSProperties, state: any) => ({
    ...provided,
    color: state.isSelected ? "#fff" : COLORS.greyMedium,
    cursor: "pointer",
    // backgroundColor: state.isSelected ? COLORS.sonnenBlue : "inherit",
  }),
  control: (provided: React.CSSProperties, state: any) => ({
    ...provided,
    background: "#fff",
    padding: "5px",
    border: state.isFocused
      ? `1px solid ${COLORS.sonnenBlue}`
      : `1px solid ${COLORS.greyBlue}`,
    borderRadius: "4px",
    cursor: "pointer",
  }),
};

export default CustomStyles;

import * as React from "react";

// Utils
import { Icon } from "../../types/Icon.types";

const IconTrash: React.FC<Icon> = (props: Icon) => {
  const { width = 14, height = 16, color = "#282E44" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 14 16">
      <g stroke={color} fill="none" fillRule="evenodd">
        <path d="M12.5 3.5v11a1 1 0 01-1 1h-9a1 1 0 01-1-1v-11" />
        <path d="M7 12.5v-6M9.5 12.5v-6M4.5 12.5v-6" strokeLinecap="round" />
        <path d="M5.36.5h3.28a.5.5 0 01.474.342l.667 2a.5.5 0 01-.475.658H4.694a.5.5 0 01-.475-.658l.667-2A.5.5 0 015.36.5z" />
        <path d="M.5 3.5h13" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default IconTrash;

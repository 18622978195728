export const GET_PRODUCTS_BY_INTERNAL_ID = `
query GetProductsByInternalId($ids: [ID]!) {
  products(where: {in: $ids}) {
    nodes {
      id
      title
      productDetails {
        price
        images {
          sourceUrl(size: THUMBNAIL)
        }
        variantSettings {
          variants {
            variant
            ean
            price
          }
        }
        shipmentexpress {
          supported
          shippingtime
        }
        shipmentstandard {
          supported
          shippingtime
        }
      }
      productCategories {
        nodes {
          slug
        }
      }
    }
  }
}
`;

export const CREATE_ORDER = `
  mutation CreateOrder($metadata: String!) {
    createOrder(input: {clientMutationId: "createOrder", title: "Bestellung", metadata: $metadata, status: PUBLISH}) {
      order {
        id
        databaseId
      }
    }
  }
`;

export const CANCEL_ORDER = `
  mutation UpdateOrder($id: ID!) {
    updateOrder(input: {clientMutationId: "updateOrder", id: $id, orderStatuses: {nodes: {slug: "cancelled"}, append: false}}) {
      order {
        id
        orderStatuses {
          nodes {
            id
            slug
          }
        }
      }
    }
  }
`;

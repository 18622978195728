import * as React from "react";
import { NavLink } from "react-router-dom";

// Context
import { useBasketState } from "../../contexts/Basket.context";

// Components
import IconBasket from "../Icons/IconBasket";

// Types
import { Subheader } from "../../types/Header.types";

// Styles
import "./SubheaderShop.scss";
import IconAllProducts from "../Icons/IconAllProducts";

const SubheaderShop: React.FC<Subheader> = (props: Subheader) => {
  const { data, parentPath } = props;

  const basket = useBasketState();

  return (
    <div className="subheader">
      <div className="subheader__inner">
        <div className="subheader__nav">
          {data.map((item: any) => (
            <NavLink
              key={item.id}
              to={`${parentPath}/category/${item.slug}`}
              className="subheader__item"
            >
              <div className="subheader__item__content">
                <img src={item.icon} alt={item.name} />
                <p>{item.name}</p>
              </div>
            </NavLink>
          ))}
          <NavLink
            to={`${parentPath}/category/all`}
            className="subheader__item"
          >
            <div className="subheader__item__content">
              <IconAllProducts />
              <p>Alle Artikel</p>
            </div>
          </NavLink>
        </div>
        <div className="subheader__basket">
          <NavLink to="/shop/orders" className="subheader__item">
            <div className="subheader__item__content">
              <p>Bestellungen</p>
            </div>
          </NavLink>
          <NavLink to="/shop/basket" className="subheader__item">
            <div className="subheader__item__content">
              <IconBasket />
              <p>Warenkorb ({basket.items.length})</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SubheaderShop;

import * as React from "react";

// Utils
import { Icon } from "../../types/Icon.types";

const IconBasket: React.FC<Icon> = (props: Icon) => {
  const { width = 23, height = 18, color = "#6e6373" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 18">
      <g fill="none" fillRule="evenodd">
        <path d="M0-3h24v24H0z" />
        <path
          d="M18 17a1 1 0 100-2 1 1 0 000 2zM9 17a1 1 0 100-2 1 1 0 000 2z"
          stroke={color}
          strokeWidth={2}
        />
        <path
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 1h3l1 3M5 4h17l-2.5 8h-12z"
        />
      </g>
    </svg>
  );
};

export default IconBasket;

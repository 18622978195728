import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// Components
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";

// Utils
import { GET_HOMEPAGE } from "./Home.queries";

const QUERY_HOMEPAGE = gql`
  ${GET_HOMEPAGE}
`;

const Home: React.FC = () => {
  const { loading, data } = useQuery(QUERY_HOMEPAGE);
  const [homepage, setHomepage] = React.useState<any>({});

  React.useEffect(() => {
    if (data) {
      const home = data.pages.nodes.filter((page: any) => page.isFrontPage)[0];
      setHomepage(home);
    }
  }, [data]);

  return (
    <div className="home">
      <Header subheaderData={homepage?.subnavigation?.sections} />
      {loading && <Loader />}
      {homepage && (
        <div className="container">
          <div
            className="home"
            dangerouslySetInnerHTML={{
              __html: homepage.content_mapped,
            }}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Home;

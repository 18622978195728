import * as React from "react";

// Utils
import {
  State,
  BasketItem,
  Action,
  BasketProviderProps,
  Dispatch,
} from "../types/Basket.types";

const LOCAL_STORAGE_KEY = "sonnen_partner_basket";

const emptyDummyState: State = {
  shipment: "standard",
  items: [],
};
let initialState: State;
const localStorageData = localStorage.getItem(LOCAL_STORAGE_KEY);

if (localStorageData) {
  initialState = JSON.parse(localStorageData);
  initialState = {
    ...initialState,
  };
} else {
  initialState = emptyDummyState;
}

const BasketStateContext = React.createContext<State | undefined>(undefined);
const BasketDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const updateLocalStorage = (state: State) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const basketReducer = (state: State, action: Action) => {
  let newState;
  const { type, payload } = action;

  switch (type) {
    case "add": {
      newState = {
        ...state,
        items: [...state.items, payload],
      };

      updateLocalStorage(newState);

      return newState;
    }

    case "delete":
      newState = {
        ...state,
        items: state.items.filter((item: BasketItem) => {
          return item.basketId !== payload.basketId;
        }),
      };

      updateLocalStorage(newState);

      return newState;

    case "updateAmount":
      newState = {
        ...state,
        items: state.items.map((item: BasketItem) => {
          if (item.basketId === payload.basketId) {
            item.amount = payload.amount;
          }

          return item;
        }),
      };

      updateLocalStorage(newState);

      return newState;

    case "updateShipment":
      newState = {
        ...state,
        shipment: payload.shipment,
      };

      updateLocalStorage(newState);

      return newState;

    case "clearBasket":
      newState = emptyDummyState;
      updateLocalStorage(newState);

      return newState;

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const BasketProvider = ({ children }: BasketProviderProps) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    basketReducer,
    initialState
  );

  return (
    <BasketStateContext.Provider value={state as State}>
      <BasketDispatchContext.Provider value={dispatch}>
        {children}
      </BasketDispatchContext.Provider>
    </BasketStateContext.Provider>
  );
};

const useBasketState = () => {
  const context = React.useContext(BasketStateContext);

  if (context === undefined) {
    throw new Error("useBasketState must be used within a BasketProvider");
  }

  return context;
};

const useBasketDispatch = () => {
  const context = React.useContext(BasketDispatchContext);

  if (context === undefined) {
    throw new Error("useBasketDispatch must be used within a BasketProvider");
  }

  return context;
};

export { BasketProvider, useBasketState, useBasketDispatch };

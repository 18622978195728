import * as React from "react";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

// Components
import InputText from "../InputText/InputText";

// Utils
import { useUserDispatch } from "../../contexts/User.context";
import { LOGIN } from "../../queries/User.queries";

// Styles
import BackgroundImage from "../../static/images/login-background.jpg";
import LoginIllustration from "../../static/images/login-illustration.png";
import SonnenLogo from "../../static/images/logo-sonnen-white.svg";
import "./Login.scss";

const MUTATION_LOGIN = gql`
  ${LOGIN}
`;

const Login: React.FC = () => {
  /**
   * TODO: more sophisticated check to determine whether user is logged in or not!
   */
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const [login] = useMutation(MUTATION_LOGIN);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      login({ variables: { ...values } })
        .then((response) => {
          const { login: loginData } = response.data;
          userDispatch({ type: "login", payload: loginData });
          history.push("/");
        })
        .catch((err) => {
          if (
            err.message.indexOf("incorrect_password") > 0 ||
            err.message.indexOf("invalid_username") > 0 ||
            err.message.indexOf("invalid_email") > 0
          ) {
            alert("User unknown or incorrect User / Password combination");
          }
        });
    },
  });

  return (
    <div
      className="login"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="login-container">
        <div className="login-container__inner">
          <div className="login-container__header">
            <img src={SonnenLogo} alt="sonnen" />
          </div>
          <div className="login-container__intro">
            <img src={LoginIllustration} alt="Login" />
            <h1>Herzlich Willkommen beim sonnen Marketing-Support</h1>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputText
              id="username"
              label="E-Mail"
              value={formik.values.username}
              onChange={formik.handleChange}
              required
            />
            <InputText
              id="password"
              type="password"
              className="mt-3"
              label="Passwort"
              value={formik.values.password}
              onChange={formik.handleChange}
              required
            />
            <button type="submit" className="mt-4">
              Einloggen
            </button>
          </form>
          <p className="login-container__register text-muted">
            Sie haben bisher keinen Account?{" "}
            <Link to="/register">Jetzt registrieren.</Link>
          </p>
          <p className="login-container__reset text-muted">
            <Link to="/requestReset">Passwort vergessen?</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;

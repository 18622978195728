import * as React from "react";

// Components
import AddressField from "../AddressField/AddressField";
import OrderItemOverview from "../OrderItemOverview/OrderItemOverview";

// Utils
import { OrderDetails } from "../../types/Order.types";

// Styles
import "./OrderItemDetails.scss";

interface OrderItemDetails {
  orderId: string;
  publicOrderId: number;
  orderStatus: string;
  details: OrderDetails;
  cancelOrder?: (orderId: string) => void;
}

const OrderItemDetails: React.FC<OrderItemDetails> = (
  props: OrderItemDetails
) => {
  const { orderId, publicOrderId, orderStatus, details, cancelOrder } = props;

  const handleCancelOrder = () => {
    if (cancelOrder) {
      cancelOrder(orderId);
    }
  };

  return (
    <div className="row order-item-details">
      <div className="col-12 col-sm-7">
        <OrderItemOverview
          orderId={orderId}
          items={details.items}
          shipment={details.shipment}
        />
      </div>
      <div className="col-12 col-sm-4 offset-sm-1">
        <div className="row">
          <div className="col-12 mb-5">
            <h4>Lieferadresse</h4>
            <AddressField address={details.addressShipping} type="small" />
          </div>
          <div className="col-12 mb-5">
            <h4>Rechnungsadresse</h4>
            <AddressField address={details.addressBilling} type="small" />
          </div>
          {(orderStatus === "received" || orderStatus === "processed") && (
            <div className="col-12 mb-2">
              <button className="--red --outline" onClick={handleCancelOrder}>
                Bestellung stonieren
              </button>
            </div>
          )}
          <div className="col-12">
            <p className="order-item-details__contact --muted">
              Sollten Sie Fragen oder Wünsche zur Bestellung haben, wenden Sie
              sich bitte unter Nennung ihrer Bestellnummer per Mail an{" "}
              <a
                href={`mailto:partner-shop@sonnen.de?subject=Bestellnummer%20${publicOrderId}&body=%0D%0A%0D%0A%0D%0ABestellnummer%20${publicOrderId}`}
              >
                partner-shop@sonnen.de
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemDetails;

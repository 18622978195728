import IconFolder from "../icons/icon-folder.png";
import IconUnknown from "../icons/icon-unknown.png";
import IconPDF from "../icons/icon-pdf.png";
import IconJPG from "../icons/icon-jpg.png";
import IconPNG from "../icons/icon-png.png";
import IconXLS from "../icons/icon-xls.png";
import IconEPS from "../icons/icon-eps.png";
import IconDOC from "../icons/icon-doc.png";
import IconPPT from "../icons/icon-ppt.png";

export const getIconForFileType = (fileType: string): string => {
  switch (fileType) {
    case "PDF":
      return IconPDF;

    case "JPG":
    case "JPEG":
      return IconJPG;

    case "PNG":
      return IconPNG;

    case "EPS":
    case "SVG":
      return IconEPS;

    case "PPT":
    case "PPTX":
      return IconPPT;

    case "XLS":
    case "XLSX":
      return IconXLS;

    case "DOC":
    case "DOCX":
      return IconDOC;

    case "FOLDER":
      return IconFolder;

    default:
      return IconUnknown;
  }
};

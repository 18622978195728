import * as React from "react";

// Styles
import "./InputAmount.scss";

interface InputAmount {
  id: string;
  className?: string;
  label?: string;
  centerInput?: boolean;
  value: number;
  min?: number;
  max?: number;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputAmount: React.FC<InputAmount> = (props: InputAmount) => {
  const {
    id,
    className = "",
    label,
    centerInput,
    value = 1,
    min,
    max,
    required,
    onChange,
  } = props;

  return (
    <div className={`input-amount ${className}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type="number"
        className={centerInput ? "mx-auto" : ""}
        id={id}
        min={min || 1}
        max={max || 9999999}
        value={value}
        pattern="^[0-9]$"
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default InputAmount;

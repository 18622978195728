import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// Components
import Card from "../Card/Card";
import BackButton from "../BackButton/BackButton";
import Loader from "../Loader/Loader";

// Styles
import "./ProductList.scss";

// Utils
import { GET_PRODUCTS_BY_CATEGORY_SLUG } from "./ProductList.queries";
import { Product } from "../../types/Product.types";
import convertToCurrency from "../../utils/FormatNumber";

const QUERY_PRODUCTS = gql`
  ${GET_PRODUCTS_BY_CATEGORY_SLUG}
`;

interface ProductList {
  parentPath: string;
}

const ProductList: React.FC<ProductList> = (props: ProductList) => {
  const { categorySlug }: any = useParams();
  const { parentPath } = props;
  const [products, setProducts] = React.useState([]);

  const handleComplete = (data: any) => {
    setProducts(data.productCategories.nodes[0].products.nodes);
  };

  const { loading } = useQuery(QUERY_PRODUCTS, {
    variables: { slug: categorySlug },
    onCompleted: handleComplete,
  });

  return (
    <div className="productList">
      <div className="row">
        <div className="col">
          <BackButton to="/shop">Zurück</BackButton>
        </div>
      </div>
      <div className="productList__products">
        <div className="row">
          {loading && (
            <div className="col-12 align-center">
              <Loader />
            </div>
          )}
          {!loading &&
            products &&
            products.map((product: Product) => {
              return (
                <div
                  className="col-6 col-sm-4"
                  key={`${product.title}-${product.id}`}
                >
                  <Card
                    title={product.title}
                    href={`${parentPath}/category/${categorySlug}/${product.id}`}
                    text={`${convertToCurrency(
                      product.startingPrice,
                      product.hasMultiplePrices
                    )}`}
                    image={{
                      src: product.productDetails.images
                        ? product.productDetails.images[0].sourceUrl
                        : "",
                      altText: product.title,
                    }}
                    textAlign="left"
                    headlineSize="small"
                    imageStyle="cover"
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductList;

import * as React from 'react';

// Styles
import './Loader.scss';

interface ILoader {
  className?: string;
}

const Loader: React.FunctionComponent<ILoader> = ({ className }: ILoader) => (
  <div className={`loader ${className}`}>
    <div className="bouncingLoader">
      <div />
    </div>
  </div>
);

export default Loader;

import * as React from "react";

// Components
import IconClose from "../../icons/IconClose";

// Styles
import "./Popup.scss";

interface Popup {
  imageUrl: string;
  closeFn: () => void;
}

const Popup: React.FC<Popup> = (props: Popup) => {
  const { imageUrl, closeFn } = props;

  return (
    <div className="popup">
      <img src={imageUrl} alt="Popup Image" />
      <div className="popup__close" onClick={closeFn}>
        <IconClose />
      </div>
    </div>
  );
};

export default Popup;

import * as React from "react";

// Utils
import { OrderItems } from "../../types/Order.types";
import {
  SHIPMENT_EXPRESS,
  SHIPMENT_STANDARD,
} from "../../constants/ShipmentCost";
import convertToCurrency from "../../utils/FormatNumber";

// Styles
import "./OrderItemOverview.scss";

interface OrderItemOverview {
  items: OrderItems[];
  shipment: string;
  orderId: string;
}

const OrderItemOverview: React.FC<OrderItemOverview> = (
  props: OrderItemOverview
) => {
  const { items, shipment, orderId } = props;

  const calculateSumOfItems = () => {
    let sum = items.reduce((sum, item) => sum + item.amount * item.price, 0);
    return sum;
  };

  const calculateSumTotal = () => {
    let sum = calculateSumOfItems();

    if (shipment === "express") sum += 15;

    return sum;
  };

  return (
    <div className="order-item-overview">
      <table>
        <tbody>
          {items.map((item: OrderItems, index: number) => (
            <tr key={`${orderId}-${index}-${item.item.id}`}>
              <td className="order-item-overview__title">{item.item.title}</td>
              <td className="order-item-overview__amount">{item.amount}</td>
              <td className="order-item-overview__price">
                {convertToCurrency(item.amount * item.price)}
              </td>
            </tr>
          ))}
          <tr className="order-item-overview__sum">
            <td className="--no-border" />
            <td>Zwischensumme</td>
            <td>{convertToCurrency(calculateSumOfItems())}</td>
          </tr>
          <tr className="order-item-overview__shipping">
            <td className="--no-border" />
            {shipment === "express" ? (
              <>
                <td>Versand (Express)</td>
                <td>{convertToCurrency(SHIPMENT_EXPRESS)}</td>
              </>
            ) : (
              <>
                <td>Versand (Standard)</td>
                <td>{convertToCurrency(SHIPMENT_STANDARD)}</td>
              </>
            )}
          </tr>
          <tr className="order-item-overview__total">
            <td className="--no-border" />
            <td>Gesamt</td>
            <td>{convertToCurrency(calculateSumTotal())}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderItemOverview;

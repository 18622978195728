import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";

// Components
import IconDownload from "../../icons/IconDownload";

// Utils
import CONFIG from "../../config";
import { formatSize, formatUnixToRelative } from "../../utils/FormatNumber";
import { getIconForFileType } from "../../utils/FileTypeIcon.utils";
import { sortDocuments } from "../../utils/Document.utils";
import { GET_DOCUMENTS_ROOT } from "./Documents.queries";

// Types
import { Document } from "../../types/Document.types";
import { DocumentsResponse } from "../../types/Document.types";

// Styles
import "./DocumentTable.scss";

const QUERY_DOCUMENTS_ROOT = gql`
  ${GET_DOCUMENTS_ROOT}
`;

interface DocumentTable {
  onUrlUpdate?: (path: string) => void;
}

const getPluralizationString = (count: any, word: string) => {
  if (count === 1) {
    return `${count} ${word}`;
  }

  return `${count} ${word}s`;
};

const DocumentTable: React.FC<DocumentTable> = (props: DocumentTable) => {
  const { onUrlUpdate } = props;
  const history = useHistory();
  const { url } = useRouteMatch();

  React.useEffect(() => {
    if (url && onUrlUpdate) {
      onUrlUpdate(url);
    }
  }, [url, onUrlUpdate]);

  /*
    We use a weird way to get the params. Since we dont know how many
    subfolders will be queried, the route params are set 
    with an * (<Route path={`${path}/*`}>) instead of :paramName. 
    By using this method we can query an undetermined number of subdirectories.
  */
  const params: any = useParams();
  const filePath = params[0];
  const [sortedData, setSortedData] = React.useState<Document[] | undefined>(
    undefined
  );

  const [folders, setFolders] = React.useState(0);
  const [files, setFiles] = React.useState(0);
  const [size, setSize] = React.useState(0);

  const handleComplete = (data: DocumentsResponse) => {
    if (data) {
      const { documents } = data;
      const { folders, files, size } = documents;

      const sortedDocuments = sortDocuments(documents.data);

      setFolders(folders);
      setFiles(files);
      setSize(size);
      setSortedData(sortedDocuments);
    }
  };

  const { error } = useQuery(QUERY_DOCUMENTS_ROOT, {
    variables: { path: filePath },
    onCompleted: handleComplete,
  });

  if (error) {
    console.error(`[DocumentTable.tsx] ${error.message}`);
  }

  const handleClick = (item: Document) => {
    if (item.isFolder) {
      history.push(`${url}/${item.name}`);
    }
  };

  const handleDownload = (path: string, fileName: string) => {
    const downloadPath = path.replace(CONFIG.documentPath, CONFIG.backendUrl);

    let link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);

    link.download = fileName;
    link.href = downloadPath;
    link.target = "_blank";

    link.click();

    document.body.removeChild(link);
  };

  return (
    <div className="documents-table">
      <table>
        <thead>
          <tr>
            <th />
            <th className="--text-left">Name</th>
            <th className="hidden-sm">File type</th>
            <th className="--text-right hidden-sm">Size</th>
            <th className="--text-right hidden-sm">Modified</th>
          </tr>
        </thead>
        <tbody>
          {sortedData &&
            sortedData.map((item: Document) => {
              return (
                <tr
                  key={item.key}
                  data-is-folder={item.isFolder}
                  onClick={() => handleClick(item)}
                >
                  <td>
                    <img
                      className="documents-table__file-icon"
                      src={getIconForFileType(item.extension || "FOLDER")}
                      alt={
                        item.extension
                          ? `${item.extension} File Icon`
                          : "Folder Icon"
                      }
                    />
                  </td>
                  <td className="documents-table__name">
                    <p>{item.name}</p>{" "}
                    {!item.isFolder && (
                      <IconDownload
                        onClick={() => handleDownload(item.path, item.name)}
                      />
                    )}
                  </td>
                  <td className="--text-center hidden-sm">
                    {item.isFolder ? "Folder" : item.extension}
                  </td>
                  <td className="--text-right hidden-sm">
                    {formatSize(item.size)}
                  </td>
                  <td className="--text-right hidden-sm">
                    {formatUnixToRelative(item.modified)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="documents-table__meta">
        <p>
          {getPluralizationString(folders, "Folder")} and{" "}
          {getPluralizationString(files, "File")}
        </p>
        <p>{formatSize(size)}</p>
      </div>
    </div>
  );
};

export default DocumentTable;

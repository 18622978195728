import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// Components
import Header from "../Header/Header";
import DocumentTable from "../DocumentTable/DocumentTable";
import Footer from "../Footer/Footer";

const Documents: React.FC = () => {
  let { path } = useRouteMatch();

  const [subheaderPath, setSubheaderPath] = React.useState("");

  return (
    <div className="page-documents">
      <Header
        subheader="documents"
        subheaderData={subheaderPath}
        parentPath={path}
      />
      <div className="container">
        <div className="row my-5">
          <div className="col-12">
            <Switch>
              <Route exact path={`${path}/`}>
                <DocumentTable onUrlUpdate={setSubheaderPath} />
              </Route>
              <Route path={`${path}/*`}>
                <DocumentTable onUrlUpdate={setSubheaderPath} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Documents;

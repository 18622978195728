import React from "react";
import { Link } from "react-router-dom";

// Components
import IconCaretRight from "../../icons/IconCaretRight";

// Types
import { Subheader } from "../../types/Header.types";

// Styles
import "./SubheaderDocuments.scss";

const SubheaderDocuments: React.FC<Subheader> = (props: Subheader) => {
  const { data, parentPath } = props;

  const splittedData = data
    .split("/")
    .filter((item: any) => item.length > 0 && item !== "documents");

  const constructRelativeLink = (index: number, item: any) => {
    let relativeLink = parentPath;

    if (index > 0) {
      for (let i = 0; i < index; i++) {
        relativeLink = `${relativeLink}/${splittedData[i]}`;
      }
    }

    relativeLink = `${relativeLink}/${item}`;

    return relativeLink;
  };

  return (
    <div>
      <div className="subheader__documents">
        <div className="subheader__documents__breadcrumb">
          <div className="subheader__documents__breadcrumb__item">
            <Link to={`${parentPath}`}>Home</Link>
          </div>
          {splittedData.map((item: any, index: number) => {
            const relativeLink = constructRelativeLink(index, item);

            return (
              <div
                key={item}
                className="subheader__documents__breadcrumb__item --flex"
              >
                <IconCaretRight />
                <Link to={relativeLink}>{item}</Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubheaderDocuments;

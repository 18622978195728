import * as React from "react";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";

// Components
import InputText from "../InputText/InputText";

// Utils
import { RESET_USER_PASSWORD } from "../../queries/User.queries";

// Styles
import BackgroundImage from "../../static/images/login-background.jpg";
import LoginIllustration from "../../static/images/login-illustration.png";
import SonnenLogo from "../../static/images/logo-sonnen-white.svg";
import "./ResetPassword.scss";

const MUTATION_RESET_PASSWORD = gql`
  ${RESET_USER_PASSWORD}
`;

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [resetPassword] = useMutation(MUTATION_RESET_PASSWORD);
  const [resetKey, setResetKey] = React.useState<string | null>(null);
  const [userLogin, setUserLogin] = React.useState<string | null>(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("key") && urlParams.has("user")) {
      setResetKey(urlParams.get("key"));
      setUserLogin(urlParams.get("user"));
    }
  }, [setResetKey]);

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordCheck: "",
    },
    onSubmit: (values) => {
      const { password, passwordCheck } = values;

      if (password === passwordCheck) {
        resetPassword({
          variables: {
            key: resetKey,
            login: userLogin,
            password: values.password,
          },
        })
          .then(() => {
            toast.success(
              "Ihr Passwort wurde geändert. Sie werden zum Login weitergeleitet.",
              { onClose: () => history.push("/login") }
            );
          })
          .catch((error) => {
            toast.error(
              "Fehler beim Zurücksetzen des Passworts. Bitte wenden Sie sich an den Support."
            );
          });
      } else {
        toast.error("Die eingegebenen Passwörter stimmen nicht überein.", {
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <div
      className="resetPassword"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="resetPassword-container">
        <div className="resetPassword-container__inner">
          <div className="resetPassword-container__header">
            <img src={SonnenLogo} alt="sonnen" />
          </div>
          <div className="resetPassword-container__intro">
            <img src={LoginIllustration} alt="resetPassword" />
            <h1>Neues Passwort erstellen</h1>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputText
              id="password"
              type="password"
              label="Passwort"
              minlength={6}
              value={formik.values.password}
              onChange={formik.handleChange}
              required
            />
            <InputText
              id="passwordCheck"
              type="password"
              className="mt-3"
              label="Passwort wiederholen"
              minlength={6}
              value={formik.values.passwordCheck}
              onChange={formik.handleChange}
              required
            />
            <button type="submit" className="mt-4">
              Passwort zurücksetzen
            </button>
          </form>
          <p className="resetPassword-container__register text-muted">
            Zurück zum <Link to="/login">Login.</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

// Components
import HeaderMenuItem from "./HeaderMenuItem";
import SubheaderDefault from "../SubheaderDefault/SubheaderDefault";
import SubheaderShop from "../SubheaderShop/SubheaderShop";
import SubheaderDocuments from "../SubheaderDocuments/SubheaderDocuments";

// Styles
import "./Header.scss";

// Assets
import SonnenLogo from "../../static/images/logo-sonnen-white.svg";

// Misc
import { GET_MENU_ITEMS } from "./Header.queries";
import { MenuItem } from "../../types/Menu.types";
import UserAvatar from "../UserAvatar/UserAvatar";

const QUERY_MENU_ITEMS = gql`
  ${GET_MENU_ITEMS}
`;

interface Header {
  subheader?: "shop" | "documents";
  subheaderData?: any;
  parentPath?: string;
}

const getHeaderMenuItems = (menuData: any) => {
  const { nodes } = menuData.menus;

  const headerMenu = nodes.filter((node: any) => node.slug === "header-menue");

  const { nodes: menuItems } = headerMenu[0].menuItems;

  return menuItems;
};

const Header: React.FC<Header> = (props: Header) => {
  const { subheader, subheaderData, parentPath } = props;
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const { data: menuItemsData } = useQuery(QUERY_MENU_ITEMS);

  const renderSubheader = (): React.ReactNode => {
    switch (subheader) {
      case "shop":
        return <SubheaderShop data={subheaderData} parentPath={parentPath} />;

      case "documents":
        return (
          <SubheaderDocuments data={subheaderData} parentPath={parentPath} />
        );

      default:
        return <SubheaderDefault data={subheaderData} />;
    }
  };

  React.useEffect(() => {
    if (menuItemsData) {
      const headerMenuItems = getHeaderMenuItems(menuItemsData);
      setMenuItems(headerMenuItems);
    }
  }, [menuItemsData, setMenuItems]);

  return (
    <header className="mainHeader">
      <div className="mainHeader__inner">
        <div className="mainHeader__branding">
          <Link to="/">
            <img src={SonnenLogo} alt="sonnen | Partner Marketing Support" />
            <span className="hidden-sm">Partner Marketing Support</span>
          </Link>
        </div>
        <nav className="mainHeader__navigation hidden-sm">
          <ul>
            {menuItems.map((menuItem: MenuItem) => (
              <HeaderMenuItem
                key={menuItem.id}
                url={menuItem.url}
                label={menuItem.label}
              />
            ))}
          </ul>
          <div className="mainHeader__navigation__user">
            <UserAvatar />
          </div>
        </nav>
      </div>
      {subheaderData && renderSubheader()}
    </header>
  );
};

export default Header;

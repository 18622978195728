import * as React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

// Context
import { useUserState } from "../../contexts/User.context";

// Utils
import { LOCAL_STORAGE_AUTH_TOKEN } from "../../constants/LocalStorage";

interface PrivateRoute {
  path: string;
  exact?: boolean;
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRoute> = (props: PrivateRoute) => {
  const { path, exact, children, ...rest } = props;
  const location = useLocation();
  const user = useUserState();

  const hasLocalStorageItem = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={() =>
        hasLocalStorageItem || user.authToken ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;

import * as React from "react";
import Swiper from "react-id-swiper";
import classNames from "classnames";

// Styles
import "./ProductImages.scss";

// Utils
import { ProductImage, ProductDownload } from "../../types/Product.types";
import Download from "../../icons/IconDownload";
interface ProductImages {
  images: ProductImage[];
  download?: ProductDownload;
}

const ProductImages: React.FC<ProductImages> = (props: ProductImages) => {
  const { images, download } = props;

  const swiperParams = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoHeight: true,
  };

  return (
    <div
      className={classNames("product-images", {
        "--slideshow": images.length > 1,
        "--single": images.length === 1,
      })}
    >
      {images.length > 1 ? (
        <Swiper {...swiperParams}>
          {images.map((image: ProductImage) => {
            return (
              <div key={image.id}>
                <img src={image.sourceUrl} alt={image.altText} />
              </div>
            );
          })}
        </Swiper>
      ) : (
        <img src={images[0].sourceUrl} alt={images[0].altText} />
      )}
      {download && download.file && (
        <a
          href={download.file.mediaItemUrl}
          rel="noopener noreferrer"
          target="_blank"
          download
          className="product-images__download"
        >
          <Download strokeWidth={2} />
          <span>{download.label}</span>
        </a>
      )}
    </div>
  );
};

export default ProductImages;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink, ApolloClient, InMemoryCache } from "apollo-boost";
import * as serviceWorker from "./serviceWorker";

// Components
import App from "./App";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

// Links
import { authLink } from "./utils/links/authLink";
import { tokenLink } from "./utils/links/tokenLink";
import { errorLink } from "./utils/links/errorLink";
import { httpLink } from "./utils/links/httpLink";

// Styles
import "./styles/styles.scss";

// Utils
import { UserProvider } from "./contexts/User.context";

const link = ApolloLink.from([authLink, tokenLink, errorLink, httpLink]);

const client = new ApolloClient({ link, cache: new InMemoryCache() });

const Index = () => (
  <Router>
    <ScrollToTop />
    <ApolloProvider client={client}>
      <UserProvider>
        <App />
      </UserProvider>
    </ApolloProvider>
  </Router>
);

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note that this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

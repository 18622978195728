import * as React from "react";

// Utils
import {
  State,
  Action,
  UserProviderProps,
  Dispatch,
} from "../types/User.types";

import {
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from "../constants/LocalStorage";

let initialState: State = {
  authToken: "",
  refreshToken: "",
  user: {
    id: "",
    firstName: "",
    lastName: "",
    avatar: { url: "" },
    addresses: {
      addressShipping: null,
      addressBilling: null,
    },
  },
};

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const convertNullToString = (payload: any) => {
  const {
    addresses: { addressBilling, addressShipping },
  } = payload;

  for (let item in addressBilling) {
    if (addressBilling[item] === null) addressBilling[item] = "";
  }

  for (let item in addressShipping) {
    if (addressShipping[item] === null) addressShipping[item] = "";
  }

  return {
    ...payload,
    addresses: {
      addressBilling,
      addressShipping,
    },
  };
};

const userReducer = (state: State, action: Action) => {
  let newState;
  const { type, payload } = action;

  switch (type) {
    case "login": {
      newState = {
        ...payload,
      };

      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, payload.authToken);
      localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, payload.refreshToken);

      return newState;
    }

    case "logout": {
      newState = initialState;

      localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

      return newState;
    }

    case "setUser": {
      newState = {
        ...state,
        user: convertNullToString(payload),
      };

      return newState;
    }

    case "updateAddressShipping": {
      newState = {
        ...state,
        user: {
          ...state.user,
          addresses: {
            ...state.user.addresses,
            addressShipping: payload,
          },
        },
      };

      return newState;
    }

    case "updateAddressBilling": {
      newState = {
        ...state,
        user: {
          ...state.user,
          addresses: {
            ...state.user.addresses,
            addressBilling: payload,
          },
        },
      };

      return newState;
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [state, dispatch] = React.useReducer<State | any>(
    userReducer,
    initialState
  );

  return (
    <UserStateContext.Provider value={state as State}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
};

const useUserDispatch = () => {
  const context = React.useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }

  return context;
};

export { UserProvider, useUserState, useUserDispatch };

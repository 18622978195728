import * as React from "react";

// Components
import OrderItem from "../OrderItem/OrderItem";

// Utils
import { Order } from "../../types/Order.types";

// Styles
import "./OrderOverview.scss";

interface OrderOverview {
  orders: Order[];
  cancelOrder?: (orderId: string) => void;
}

const OrderOverview: React.FC<OrderOverview> = (props: OrderOverview) => {
  const { orders, cancelOrder } = props;

  return (
    <div className="order-overview">
      {orders.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>
                <span className="hidden-sm">Bestellung</span> Nr.
              </th>
              <th className="hidden-sm">Datum</th>
              <th className="hidden-sm">Anzahl Artikel</th>
              <th className="hidden-sm">Gesamtwert</th>
              <th colSpan={2}>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order: Order) => (
              <OrderItem
                key={order.id}
                order={order}
                cancelOrder={cancelOrder}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p>Keine Einträge</p>
      )}
    </div>
  );
};

export default OrderOverview;

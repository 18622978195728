import * as React from "react";

// Styles
import "./OrderStatus.scss";

interface OrderStatus {
  status: string;
  description: string;
}

export const OrderStatus: React.FC<OrderStatus> = (props: OrderStatus) => {
  const { status, description } = props;

  return (
    <div className={`order-status --${status}`}>
      <p>{description}</p>
    </div>
  );
};

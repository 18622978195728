import { BasketItem } from "../../types/Basket.types";

export const subTotalReducer = (
  previousValue: BasketItem | number,
  currentValue: BasketItem
): number => {
  if (typeof previousValue === "number") {
    return (
      previousValue + currentValue.productDetails.price * currentValue.amount
    );
  }

  return (
    previousValue.productDetails.price * previousValue.amount +
    currentValue.productDetails.price * currentValue.amount
  );
};

import * as React from "react";

// Styles
import "./UserDropdown.scss";

interface IUserDropdown {
  logout: () => void;
}

const UserDropdown: React.FC<IUserDropdown> = (props: IUserDropdown) => (
  <div className="userDropdown">
    <span onClick={props.logout}>Abmelden</span>
  </div>
);

export default UserDropdown;

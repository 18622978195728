import * as React from "react";
import { Link } from "react-router-dom";

// Components
import InputAmount from "../InputAmount/InputAmount";
import IconTrash from "../Icons/IconTrash";
import ShipmentDetail from "../ShipmentDetail/ShipmentDetail";

// Context
import { useBasketState } from "../../contexts/Basket.context";

// Utils
import formatCurrency from "../../utils/FormatNumber";
import { BasketItem } from "../../types/Basket.types";
import {
  SHIPMENT_EXPRESS,
  SHIPMENT_STANDARD,
} from "../../constants/ShipmentCost";

interface BasketTableBody {
  productInformation: BasketItem[];
  handleDelete: (basketId: string) => void;
  handleAmountChange: (basketId: string, amount: number) => void;
  subTotal: number;
  total: number;
}

const BasketTableBody: React.FC<BasketTableBody> = (props: BasketTableBody) => {
  const basket = useBasketState();
  const {
    productInformation,
    handleDelete,
    handleAmountChange,
    subTotal,
    total,
  } = props;

  return (
    <tbody>
      {productInformation.length > 0 ? (
        productInformation.map((item: BasketItem) => {
          const { productDetails, productCategories } = item;

          return (
            <tr key={item.basketId} className="--hover">
              <td className="basket__product__image">
                {productDetails.images && (
                  <div>
                    <img
                      src={productDetails.images[0].sourceUrl}
                      alt={item.title}
                    />
                  </div>
                )}
              </td>
              <td className="basket__product__title">
                <span>Art. Nr. {item.variant.ean}</span>
                <div className="basket__product__title__container">
                  <Link
                    to={`/shop/category/${productCategories.nodes[0].slug}/${item.id}`}
                  >
                    <p dangerouslySetInnerHTML={{ __html: item.title }} />
                  </Link>
                  {basket.shipment === "express" &&
                    !item.productDetails.shipmentexpress.supported && (
                      <span>Kein Express-Versand möglich!</span>
                    )}
                </div>
                <span>{item.variant.label}</span>
                {item.comment && (
                  <span className="basket__product__comment">
                    {item.comment}
                  </span>
                )}
                <ShipmentDetail
                  shipment={basket.shipment}
                  standard={item.productDetails.shipmentstandard}
                  express={item.productDetails.shipmentexpress}
                />
              </td>
              <td>
                <InputAmount
                  id={`${item.basketId}-amount`}
                  value={item.amount}
                  centerInput={true}
                  onChange={(e) =>
                    handleAmountChange(
                      item.basketId,
                      parseInt(e.currentTarget.value)
                    )
                  }
                />
              </td>
              <td>
                <div
                  className="basket__delete"
                  onClick={() => handleDelete(item.basketId)}
                  tabIndex={0}
                >
                  <IconTrash />
                </div>
              </td>
              <td>{formatCurrency(productDetails.price)}</td>
              <td>{formatCurrency(item.amount * productDetails.price)}</td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>
            <p className="text-center --muted">
              Noch keine Produkte hinzugefügt
            </p>
          </td>
        </tr>
      )}
      {productInformation.length > 0 && (
        <>
          <tr className="basket__subtotal">
            <td colSpan={4} className="--no-border" />
            <td>Zwischensumme</td>
            <td>{formatCurrency(subTotal || 0)}</td>
          </tr>
          <tr className="basket__shipment">
            <td colSpan={4} className="--no-border" />
            <td>Versand</td>
            <td>
              {formatCurrency(
                basket.shipment === "express"
                  ? SHIPMENT_EXPRESS
                  : SHIPMENT_STANDARD
              )}
            </td>
          </tr>
          <tr className="basket__total">
            <td colSpan={4} className="--no-border" />
            <td>Gesamt</td>
            <td>{formatCurrency(total)}</td>
          </tr>
        </>
      )}
    </tbody>
  );
};

export default BasketTableBody;

import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

// Components
import Loader from "../Loader/Loader";

// Styles
import "./BasketOverview.scss";

// Utils
import { useBasketState } from "../../contexts/Basket.context";
import { BasketItem } from "../../types/Basket.types";
import { GET_PRODUCTS_BY_INTERNAL_ID } from "../Basket/Basket.queries";
import { enrichenBasketData } from "../../utils/BasketData.utils";
import convertToCurrency from "../../utils/FormatNumber";
import { subTotalReducer } from "../Basket/Basket.reducer";
import {
  SHIPMENT_EXPRESS,
  SHIPMENT_STANDARD,
} from "../../constants/ShipmentCost";

const QUERY_PRODUCTS = gql`
  ${GET_PRODUCTS_BY_INTERNAL_ID}
`;

const BasketOverview: React.FC = () => {
  const basket = useBasketState();
  const [subTotal, setSubTotal] = React.useState(0);
  const [shippingCost, setShippingCost] = React.useState(0);
  const [productData, setProductData] = React.useState<BasketItem[] | null>(
    null
  );
  let IDs: number[] = [];

  IDs = basket.items.map((item) =>
    IDs.indexOf(item.internalProductId) < 0 ? item.internalProductId : false
  );

  const { loading, data } = useQuery(QUERY_PRODUCTS, {
    variables: {
      ids: IDs,
    },
  });

  React.useEffect(() => {
    if (basket && data) {
      setProductData(enrichenBasketData(basket, data.products.nodes));
    }

    if (basket) {
      setShippingCost(
        basket.shipment === "standard" ? SHIPMENT_STANDARD : SHIPMENT_EXPRESS
      );
    }
  }, [basket, data]);

  React.useEffect(() => {
    if (productData) {
      setSubTotal((productData as any).reduce(subTotalReducer, 0));
    }
  }, [productData]);

  return (
    <div className="basket-overview">
      {loading && <Loader />}
      {!loading && productData && (
        <div className="basket-overview__list">
          {productData.map((product: BasketItem) => (
            <div key={product.basketId} className="basket-overview__product">
              <div className="basket-overview__product__image">
                <div className="basket-overview__product__image__container">
                  {product.productDetails.images && (
                    <img
                      src={product.productDetails.images[0].sourceUrl}
                      alt={product.title}
                    />
                  )}
                </div>
                <span className="basket-overview__product__image__amount">
                  {product.amount}
                </span>
              </div>
              <div className="basket-overview__product__details">
                <p className="basket-overview__product__title">
                  {product.title}
                </p>
                <p className="basket-overview__product__variant">
                  {product.variant.label}
                </p>
              </div>
              <div className="basket-overview__product__price">
                <p>
                  {convertToCurrency(
                    product.amount * product.productDetails.price
                  )}
                </p>
              </div>
            </div>
          ))}
          <div className="basket-overview__subtotal">
            <p className="basket-overview__amount">
              Zwischensumme: <span>{convertToCurrency(subTotal)}</span>
            </p>
            <p className="basket-overview__shipping">
              Versand: <span>{convertToCurrency(shippingCost)}</span>
            </p>
          </div>
          <div className="basket-overview__total">
            <p className="basket-overview__amount">
              Gesamt: <span>{convertToCurrency(subTotal + shippingCost)}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasketOverview;

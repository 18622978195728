import * as React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useHistory,
} from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

// Components
import BasketOverview from "../BasketOverview/BasketOverview";
import AddressShipping from "../AddressShipping/AddressShipping";
import BackButton from "../BackButton/BackButton";
import CheckoutAddressBar from "../CheckoutAddressBar/CheckoutAddressBar";

// Utils
import {
  useBasketState,
  useBasketDispatch,
} from "../../contexts/Basket.context";
import { useUserState } from "../../contexts/User.context";
import { CREATE_ORDER } from "../Basket/Basket.queries";

// Styles
import "./Checkout.scss";

const MUTATION_ORDER = gql`
  ${CREATE_ORDER}
`;

interface Checkout {
  path: string;
}

const Checkout: React.FC<Checkout> = (props: Checkout) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const basket = useBasketState();
  const basketDispatch = useBasketDispatch();
  const user = useUserState();

  const [createOrder, { data: orderData }] = useMutation(MUTATION_ORDER);

  const handleOrder = () => {
    const payload = {
      items: basket.items,
      addresses: user.user.addresses,
      shipment: basket.shipment,
    };

    createOrder({ variables: { metadata: JSON.stringify(payload) } });
  };

  React.useEffect(() => {
    if (orderData) {
      const { databaseId: orderId } = orderData.createOrder.order;
      basketDispatch({ type: "clearBasket", payload: {} });
      history.push(`/shop/checkout/complete/${orderId}`);
    }
  }, [orderData, basketDispatch, history]);

  return (
    <div className="checkout">
      <div className="row">
        <div className="col-12 col-sm-6 checkout__basket-overview py-3">
          <BackButton margin={false} className="mt-2 mb-3" />
          <h1>Bestellung abschließen</h1>
          <BasketOverview />
          <div className="row">
            <div className="col-6 mt-3 mb-5">
              <Link to={`/shop/basket`} className="button --outline">
                Bestellung ändern
              </Link>
            </div>
            <div className="col-6 mt-3 mb-5">
              <button
                type="button"
                onClick={handleOrder}
                disabled={
                  !user.user.addresses.addressBilling?.name ||
                  !user.user.addresses.addressShipping?.name
                }
              >
                Zahlungspflichtig bestellen
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-5 checkout__address-overview offset-sm-1 py-5">
          <Switch>
            <Route exact path={`${path}`}>
              <CheckoutAddressBar />
            </Route>
            <Route exact path={`${path}/address/shipping`}>
              <AddressShipping type="addressShipping" />
            </Route>

            <Route exact path={`${path}/address/invoice`}>
              <AddressShipping type="addressBilling" />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import * as React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

// Styles
import "./BackButton.scss";

interface BackButton {
  to?: string;
  className?: string;
  margin?: boolean;
}

const BackButton: React.FC<BackButton> = (props: BackButton) => {
  const { to, className, margin = true } = props;
  const history = useHistory();

  const handleClick = (event: React.MouseEvent) => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  const backButtonClassNames = classNames("back-button", {
    [`${className}`]: className,
    "my-4": margin,
  });

  return (
    <div className={backButtonClassNames} onClick={handleClick}>
      <span>Zurück</span>
    </div>
  );
};

export default BackButton;

import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// Components
import Card from "../Card/Card";
import ProductList from "../ProductList/ProductList";
import ProductListAll from "../ProductList/ProductListAll";
import ProductDetails from "../ProductDetails/ProductDetails";
import Header from "../Header/Header";
import Basket from "../Basket/Basket";
import Checkout from "../Checkout/Checkout";
import CheckoutComplete from "../CheckoutComplete/CheckoutComplete";
import Orders from "../Orders/Orders";
import Footer from "../Footer/Footer";

// Styles
import "./Shop.scss";

// Utils
import { GET_PRODUCT_CATEGORIES } from "./Shop.queries";
import { ProductCategory } from "../../types/Product.types";
import { SubheaderData } from "../../types/Subheader.types";

const QUERY_PRODUCT_CATEGORIES = gql`
  ${GET_PRODUCT_CATEGORIES}
`;

const Shop: React.FC = () => {
  const { path, url } = useRouteMatch();
  const [productCategories, setProductCategories] = React.useState<
    ProductCategory[]
  >([]);
  const [headline, setHeadline] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [subheader, setSubheader] = React.useState<SubheaderData[]>([]);

  const handleComplete = (data: any) => {
    if (data) {
      const subheaderData: SubheaderData[] = [];

      data.productCategories.nodes.forEach((element: ProductCategory) => {
        subheaderData.push({
          name: element.name,
          id: element.id,
          slug: element.slug,
          icon: element.productCategoryTaxonomy.categoryIcon.small.sourceUrl,
        });
      });

      /*
      const categories = data.supportCategories.nodes;
      const categoryDescription =
        data.sonnenEinstellungen.textSettings.support.descriptionCategories;
      setSupportCategories(categories);
      setDescription(categoryDescription);
      */

      const categories = data.productCategories.nodes;
      const categoryDescription =
        data.sonnenEinstellungen.textSettings.shop.descriptionCategories;
      const shopHeadline = data.sonnenEinstellungen.textSettings.shop.headline;

      setProductCategories(categories);
      setDescription(categoryDescription);
      setHeadline(shopHeadline);
      setSubheader(subheaderData);
    }
  };

  const { error } = useQuery(QUERY_PRODUCT_CATEGORIES, {
    onCompleted: handleComplete,
  });

  if (error) {
    console.error(`[Shop.tsx] ${error.message}`);
  }

  return (
    <div className="page-shop">
      <Header subheader="shop" subheaderData={subheader} parentPath={path} />
      <div className="container">
        <Switch>
          <Route exact path={path}>
            <div className="row my-4">
              <div className="col-xs-12 col-sm-6">
                <h1>{headline}</h1>
                <p>{description}</p>
              </div>
            </div>
            <div className="row">
              <div className="cardContainer">
                {productCategories.map((productCategory: ProductCategory) => (
                  <div className="col" key={productCategory.id}>
                    <Card
                      title={productCategory.name}
                      href={`${url}/category/${productCategory.slug}`}
                      text={productCategory.description}
                      image={{
                        src:
                          productCategory.productCategoryTaxonomy.categoryIcon
                            .large.sourceUrl,
                        altText:
                          productCategory.productCategoryTaxonomy.categoryIcon
                            .large.altText,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Route>
          <Route exact path={`${path}/basket`}>
            <Basket />
          </Route>
          <Route path={`${path}/checkout/complete/:orderId`}>
            <CheckoutComplete />
          </Route>
          <Route path={`${path}/checkout`}>
            <Checkout path={path} />
          </Route>
          <Route path={`${path}/orders`}>
            <Orders />
          </Route>
          <Route exact path={`${path}/category/all`}>
            <ProductListAll parentPath={path} />
          </Route>
          <Route exact path={`${path}/category/:categorySlug`}>
            <ProductList parentPath={path} />
          </Route>
          <Route exact path={`${path}/category/:categorySlug/:productId`}>
            <ProductDetails />
          </Route>
        </Switch>
      </div>
      <div className="container">
        <p className="page-shop__tax-info">Alle Preise in Netto zzgl. MwSt.</p>
      </div>
      <Footer />
    </div>
  );
};

export default Shop;

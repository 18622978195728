import * as React from "react";

// Types
import { ShipmentInfo } from "../../types/Product.types";

// Styles
import "./ProductShipmentDetails.scss";

interface IProductShipmentDetails {
  standard: ShipmentInfo;
  express: ShipmentInfo;
}

const ProductShipmentDetails: React.FC<IProductShipmentDetails> = (
  props: IProductShipmentDetails
) => {
  const { standard, express } = props;

  return (
    <div className="product-shipment-details">
      <p className="product-shipment-details__headline">Lieferzeit</p>
      <p className="product-shipment-details__type">
        Standardversand:{" "}
        <span>
          {standard.supported ? standard.shippingtime : "nicht möglich"}
        </span>
      </p>
      <p className="product-shipment-details__type">
        Expressversand:{" "}
        <span>
          {express.supported ? express.shippingtime : "nicht möglich"}
        </span>
      </p>
    </div>
  );
};

export default ProductShipmentDetails;

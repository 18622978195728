import * as React from "react";
import { Link } from "react-router-dom";

// Illustration
import Illustration from "../../static/images/order-completed-illustration.svg";

// Styles
import "./CheckoutComplete.scss";

const CheckoutComplete: React.FC = () => {
  /**
   * TODO: Use orderId to automatically expand Order when clicking Link
   *
   * Something like: to=`/shop/orders/${orderId}`
   *
   * const { orderId } = useParams();
   */

  return (
    <div className="order-complete">
      <div className="row">
        <div className="col-6 offset-3">
          <div className="order-complete__image">
            <img src={Illustration} alt="Bestellung abgeschlossen" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-3">
          <h1 className="text-center">Bestellung abgeschlossen</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-3">
          <p className="text-center">
            Ihre Bestellung wurde erfolgreich abgeschlossen. Sie können den
            Status der Bestellung jederzeit über den Menüpunkt{" "}
            <Link to="/shop/orders">Bestellungen</Link> einsehen.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-8 offset-2">
          <div className="order-complete__buttons">
            <Link to="/shop/orders">
              <button className="--outline">Bestellübersicht</button>
            </Link>
            <Link to="/shop">
              <button className="button">Zurück zum Shop</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutComplete;

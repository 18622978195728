import * as React from "react";
import Select from "react-select";

// Styles
import CustomStyles from "./InputSelect.config";
import "./InputSelect.scss";

// Utils
import { Variant } from "../../types/Product.types";
import { InputOptions } from "../../types/Input.types";
import convertToCurrency from "../../utils/FormatNumber";

interface InputSelect {
  id: string;
  label: string;
  options: Variant[];
  value: any;
  required?: boolean;
  onChange: (field: string, value: any) => void;
  onBlur: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

const InputSelect: React.FC<InputSelect> = (props: InputSelect) => {
  const { id, label, options, value, required, onChange, onBlur } = props;
  const [selectOptions, setSelectOptions] = React.useState<InputOptions[]>([]);

  React.useEffect(() => {
    if (options) {
      const optionsArray: InputOptions[] = [];

      options.forEach((option) => {
        const { ean } = option;
        const label = `${option.variant}: ${convertToCurrency(option.price)}`;
        const value = option.variant.replace(/[^A-Z0-9]/gi, "-");

        optionsArray.push({ label, value, ean });
      });

      setSelectOptions(optionsArray);
    }
  }, [options]);

  const handleChange = (value: any) => {
    onChange("variant", value);
  };

  const handleBlur = () => {
    onBlur("variant", true);
  };

  return (
    <div className="input-select">
      <label htmlFor={id}>{label}</label>
      <Select
        id={id}
        options={selectOptions}
        styles={CustomStyles}
        placeholder="Bitte auswählen"
        isSearchable={false}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        value={Object.keys(value).length === 0 ? "" : value}
      />
      <input
        className="input-select--hidden"
        value={Object.keys(value).length === 0 ? "" : value}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
};

export default InputSelect;

import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";

// Components
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";

// Utils
import { GET_DEFAULT } from "./DefaultPage.queries";

const QUERY_DEFAULT = gql`
  ${GET_DEFAULT}
`;

const DefaultPage: React.FC = () => {
  const { slug }: any = useParams();

  const { loading, error, data } = useQuery(QUERY_DEFAULT, {
    variables: {
      uri: slug,
    },
  });

  if (error) return <p>Error</p>;

  return (
    <div className="home">
      <Header subheaderData={data?.page.subnavigation?.sections} />
      {loading && <Loader />}
      {data && (
        <div className="container">
          <div
            className="default-page"
            dangerouslySetInnerHTML={{
              __html: data.page
                ? data.page.content_mapped
                : "This page does not exist",
            }}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default DefaultPage;

import * as React from "react";

// Utils
import { Address } from "../../types/Basket.types";

// Style
import "./AddressField.scss";

interface AddressField {
  address: Address | null;
  type?: "normal" | "small";
}

const AddressField: React.FC<AddressField> = (props: AddressField) => {
  const { address, type = "normal" } = props;

  return (
    <div className={`address-field --${type}`}>
      {address?.company && <p>{address.company}</p>}
      {address?.name && address.surname && (
        <p>
          {address.name} {address.surname}
        </p>
      )}
      {address?.address && <p>{address.address}</p>}
      {address?.address2 && <p>{address.address2}</p>}
      {address?.zip && address.city && (
        <p>{`${address.zip} ${address.city}`}</p>
      )}
      {address?.country && <p>{address.country}</p>}
    </div>
  );
};

export default AddressField;

import * as React from "react";
import { Switch, Route } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ToastContainer, Slide } from "react-toastify";

// Components
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Home from "./components/Home/Home";
import Shop from "./components/Shop/Shop";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import RequestPasswordReset from "./components/RequestPasswordReset/RequestPasswordReset";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Support from "./components/Support/Support";
import Documents from "./components/Documents/Documents";
import Branding from "./components/Branding/Branding";
import DefaultPage from "./components/DefaultPage/DefaultPage";
import Popup from "./components/Popup/Popup";

// Styles
import "react-toastify/dist/ReactToastify.css";

// Misc
import { BasketProvider } from "./contexts/Basket.context";
import { useUserDispatch } from "./contexts/User.context";
import { PROTECTED_QUERY } from "./queries/User.queries";
import {
  comparePopupId,
  savePopupIdToLocalStorage,
} from "./utils/PopupHandler.utils";

const USER_QUERY = gql`
  ${PROTECTED_QUERY}
`;

const App: React.FC = () => {
  const userDispatch = useUserDispatch();
  const [popupUrl, setPopupUrl] = React.useState("");
  const [popupId, setPopupId] = React.useState(0);

  const popupClose = () => {
    setPopupUrl("");
    savePopupIdToLocalStorage(popupId.toString());
  };

  const { loading } = useQuery(USER_QUERY, {
    onCompleted: (data) => {
      const { viewer: userData } = data;
      const {
        active: popupActive,
        popupImage: { databaseId: popupId, mediaItemUrl: popupImageUrl },
      } = data.sonnenEinstellungen.popupSettings;

      userDispatch({ type: "setUser", payload: userData });

      if (popupActive && !comparePopupId(popupId)) {
        setPopupUrl(popupImageUrl);
        setPopupId(popupId);
      }
    },
  });

  if (loading) {
    console.log("[App.tsx] Loading...");
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        transition={Slide}
        autoClose={3500}
      />
      {popupUrl && <Popup imageUrl={popupUrl} closeFn={popupClose} />}
      <Switch>
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/requestReset">
          <RequestPasswordReset />
        </Route>
        <Route path="/reset">
          <ResetPassword />
        </Route>
        <PrivateRoute path="/p/:slug">
          <DefaultPage />
        </PrivateRoute>
        <PrivateRoute path="/shop">
          <BasketProvider>
            <Shop />
          </BasketProvider>
        </PrivateRoute>
        <PrivateRoute path="/branding">
          <Branding />
        </PrivateRoute>
        <PrivateRoute path="/requests">
          <Support />
        </PrivateRoute>
        <PrivateRoute path="/documents">
          <Documents />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default App;

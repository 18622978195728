import * as React from "react";
import Tippy from "@tippyjs/react";

// Misc.
import { useUserState, useUserDispatch } from "../../contexts/User.context";

// Styles
import "tippy.js/dist/tippy.css";
import "./UserAvatar.scss";
import UserDropdown from "../UserDropdown/UserDropdown";
import IconCaretDown from "../../icons/IconCaretDown";

const UserAvatar: React.FC = () => {
  const user = useUserState();
  const userDispatch = useUserDispatch();

  const logout = () => {
    userDispatch({ type: "logout", payload: "" });
  };

  return (
    <div className="userAvatar">
      {user?.user && (
        <Tippy
          interactive={true}
          zIndex={99}
          placement="bottom"
          content={<UserDropdown logout={logout} />}
        >
          <div className="userAvatar__content">
            <p>{`${user.user.firstName} ${user.user.lastName || ""}`}</p>
            <IconCaretDown />
            {user.user.avatar.url && (
              <img
                src={user.user.avatar.url}
                width="24"
                height="24"
                alt={`${user.user.firstName} ${user.user.lastName}'s Avatar`}
              />
            )}
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default UserAvatar;

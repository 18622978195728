import * as React from "react";

// Styles
import "./InputText.scss";

interface InputText {
  id: string;
  label: string;
  value: string;
  minlength?: number;
  type?: "text" | "password" | "email";
  placeholder?: string;
  className?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputText> = (props: InputText) => {
  const {
    id,
    label,
    value,
    minlength,
    type = "text",
    placeholder,
    className,
    required,
    onChange,
  } = props;

  return (
    <div className={`input-text ${className}`}>
      <label htmlFor={id}>{`${label}${required ? "*" : ""}`}</label>
      <input
        type={type}
        id={id}
        placeholder={placeholder || label}
        value={value}
        minLength={minlength}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default InputText;

import * as React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { toast } from "react-toastify";

// Context
import {
  useBasketState,
  useBasketDispatch,
} from "../../contexts/Basket.context";

// Components
import BackButton from "../BackButton/BackButton";
import Loader from "../Loader/Loader";
import ShipmentSelect from "../ShipmentSelect/ShipmentSelect";

// Styles
import "./Basket.scss";

// Utils
import { GET_PRODUCTS_BY_INTERNAL_ID } from "./Basket.queries";
import { BasketItem } from "../../types/Basket.types";
import { subTotalReducer } from "./Basket.reducer";
import BasketTableBody from "./BasketTableBody";
import { enrichenBasketData } from "../../utils/BasketData.utils";
import { Link } from "react-router-dom";
import {
  SHIPMENT_EXPRESS,
  SHIPMENT_STANDARD,
} from "../../constants/ShipmentCost";

const QUERY_PRODUCTS = gql`
  ${GET_PRODUCTS_BY_INTERNAL_ID}
`;

const Basket: React.FC = () => {
  const basket = useBasketState();
  const dispatch = useBasketDispatch();
  const [getProducts, { loading, data }] = useLazyQuery(QUERY_PRODUCTS);
  const [productInformation, setProductInformation] = React.useState<
    BasketItem[] | null
  >(null);
  const [checkoutDisabled, setCheckoutDisabled] = React.useState(false);
  const [subTotal, setSubTotal] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const handleAmountChange = (basketId: string, amount: number) => {
    dispatch({
      type: "updateAmount",
      payload: { basketId, amount },
    });
  };

  const handleDelete = (basketId: string) => {
    dispatch({
      type: "delete",
      payload: { basketId },
    });
  };

  /**
   * Combine Basket Data from Basket Context with Live Information
   * from GraphQL Endpoint.
   */
  React.useEffect(() => {
    if (basket && data) {
      setProductInformation(enrichenBasketData(basket, data.products.nodes));
    }
  }, [basket, data]);

  /**
   * Reduce Pricing Information from ProductInformation Array
   * to calculate subTotal Value.
   */
  React.useEffect(() => {
    if (productInformation && productInformation.length > 0) {
      setSubTotal((productInformation as any).reduce(subTotalReducer, 0));
    }
  }, [productInformation]);

  /**
   * Check Shipment Availability
   */
  React.useEffect(() => {
    if (basket.shipment === "express") {
      const itemsWithNoExpressShipment = productInformation?.filter(
        (info: any) => !info.productDetails.shipmentexpress.supported
      );

      if (
        itemsWithNoExpressShipment &&
        itemsWithNoExpressShipment?.length > 0
      ) {
        setCheckoutDisabled(true);
      } else {
        setCheckoutDisabled(false);
      }
    } else {
      setCheckoutDisabled(false);
    }
  }, [basket.shipment, productInformation]);

  React.useEffect(() => {
    if (checkoutDisabled) {
      toast.dismiss();
      toast.error(
        "Ein oder mehrere Produkte können nicht per Express-Versand verschickt werden.",
        {
          autoClose: 9000,
        }
      );
    }
  }, [checkoutDisabled]);

  React.useEffect(() => {
    if (basket.shipment && subTotal) {
      setTotal(
        basket.shipment === "express"
          ? subTotal + SHIPMENT_EXPRESS
          : subTotal + SHIPMENT_STANDARD
      );
    }
  }, [basket.shipment, subTotal]);

  /**
   * Query GraphQL for Product Details for every Item in Basket.
   */
  React.useEffect(() => {
    //TODO: Refactor this effect to comply with Lint's react-hooks/exhaustive-deps
    let IDs: number[] = [];

    IDs = basket.items.map((item) =>
      IDs.indexOf(item.internalProductId) < 0 ? item.internalProductId : false
    );

    getProducts({ variables: { ids: IDs } });
  }, []);

  return (
    <div className="basket">
      <BackButton />
      <h1>Warenkorb</h1>
      <table className="mt-4">
        <thead>
          <tr>
            <th colSpan={2}>Artikel</th>
            <th>Anzahl</th>
            <th>Entfernen</th>
            <th>Stückpreis</th>
            <th>Gesamt-Preis</th>
          </tr>
        </thead>
        {loading && (
          <tbody>
            <tr>
              <td className="text-center" colSpan={6}>
                <Loader />
              </td>
            </tr>
          </tbody>
        )}
        {productInformation && (
          <BasketTableBody
            productInformation={productInformation}
            handleAmountChange={handleAmountChange}
            handleDelete={handleDelete}
            subTotal={subTotal}
            total={total}
          />
        )}
      </table>
      {!loading && productInformation && productInformation.length > 0 && (
        <div className="row">
          <div className="col-6 mt-4">
            <ShipmentSelect value={basket.shipment} />
            <Link to="/shop/checkout" className={`button`}>
              Checkout
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Basket;

export const GET_PRODUCT_BY_ID = `
  query GetProductById($id: ID!) {
    productBy(id: $id) {
      id
      slug
      title
      productId
      startingPrice
      hasMultiplePrices
      productDetails {
        shipmentstandard {
          supported
          shippingtime
        }
        shipmentexpress {
          shippingtime
          supported
        }
        variantSettings {
          headline
          variants {
            ean
            variant
            price
          }
        }
        showComment
        price
        download {
          label
          file {
            mediaItemUrl
            id
            title
          }
        }
        details {
          detail
        }
        description
        images {
          sourceUrl
          id
          altText
        }
      }
    }
  }
`;

export const GET_DOCUMENTS_ROOT = `
  query GetDocumentsRoot($path: String) {
    documents(path: $path) {
      files
      data {
        name
        type
        size
        path
        modified
        key
        isFolder
        extension
      }
      size
      folders
    }
  }
`;

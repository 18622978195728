export const GET_HOMEPAGE = `
  query HomepageQuery {
    pages {
      nodes {
        title
        isFrontPage
        content_mapped
        subnavigation {
          sections {
            sectionId
            label
          }
        }
      }
    }
  }
`;
